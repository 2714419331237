import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/_services/shared.service';
import { TypebenefitService } from 'src/app/_services/typebenefit.service';
import { PopupAddTypeBenefitComponent } from '../../../popupAdd/popup-add-type-benefit/popup-add-type-benefit.component';
import { PopupUpdateTypeBenefitComponent } from '../../../popupAdd/popup-update-type-benefit/popup-update-type-benefit.component';
import { TypebenefitGlassService } from 'src/app/_services/typeBenefitGlass';
import { PopupAddTypebenefitGlassComponent } from '../../../popupAdd/popup-add-typebenefit-glass/popup-add-typebenefit-glass.component';
import { PopupUpdateTypebenefitGlassComponent } from '../../../popupAdd/popup-update-typebenefit-glass/popup-update-typebenefit-glass.component';

@Component({
  selector: 'app-list-types-glass',
  templateUrl: './list-types-glass.component.html',
  styleUrls: ['./list-types-glass.component.css','../../../../../_shared/list.css']
})
export class ListTypesGlassComponent implements OnInit {

  typesBenefitsGlass :any
  sizeContent :any
  recentResultsTypesBenefitsGlass
  type: string;

  constructor(private typeBenefitGlassService :TypebenefitGlassService,private router: Router,
    private modalService: NgbModal,private sharedService :SharedService
    ) { }

  ngOnInit(): void {
    this.sharedService.submitEvent$.subscribe(() => {
      this.getTypes(); // ou toute autre action que vous souhaitez effectuer
    });
    this.getTypes()
  }


  getTypes() {
    this.typeBenefitGlassService.getTypesBenefitsGlass().subscribe((e) => {
      this.typesBenefitsGlass = e
      this.recentResultsTypesBenefitsGlass =  this.typesBenefitsGlass
      this.sizeContent=this.typesBenefitsGlass.length
    });
  }

  addType(){
    let modalRef
    modalRef =this.modalService.open(PopupAddTypebenefitGlassComponent);
  }

  goToListBenifitsGlass(){
    this.router.navigate(["admin/chantiers/vitres"]);
  }
  updateType(id){
    let modalRef
    modalRef =this.modalService.open(PopupUpdateTypebenefitGlassComponent);
    modalRef.componentInstance.idType = id;

  }

  search()
  {
    if(this.type != "" )
     {
      this.typesBenefitsGlass = this.recentResultsTypesBenefitsGlass
      this.typesBenefitsGlass = this.typesBenefitsGlass.filter(res => {
        return res.type.toLocaleLowerCase().match(this.type.toLocaleLowerCase()) 
       
      });
      this.sizeContent = this.typesBenefitsGlass.length

    
     }
     else
     {
      this.typesBenefitsGlass = this.recentResultsTypesBenefitsGlass
   
     }
  }

  goToListbenfitsArchiveds(){
    this.router.navigate(["admin/prestations/archives"]);
  }

}
