<!-- main app container -->
<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item color-blue">
        <a class="color-blue" routerLink="../">Vitres</a>
      </li>
      <li class="breadcrumb-item active color-blue" style="cursor: default">
        Créer une prestation vitres
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%">
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1">
            Créer une prestation vitres
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
      </div>
    </div>
  </div>
</header>
<br />
<div class="card m-3-cust">
  <div class="card-body ml-5">
    <form
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit()"
      class="margin-right-50"
    >
      <div *ngIf="site" class="form-row">
        <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <label
            >Nom du chantier :
            <strong style="color: #0e309f; text-decoration: underline">{{
              site.name
            }}</strong></label
          >
        </div>
        <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <label
            >Responsable :
            <strong style="color: #0e309f; text-decoration: underline">{{
              site.manager.firstName
            }}</strong></label
          >
        </div>
        <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <label
            >Client :
            <strong style="color: #0e309f; text-decoration: underline">{{
              site.client.companyName
            }}</strong></label
          >
        </div>
      </div>
      <div class="form-row">
        <div
          *ngIf="!idSite"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <label>Chantier<b style="color: red"> * </b></label>
          <ng-select
            [items]="sites"
            bindLabel="column3"
            formControlName="site"
            bindValue="_id"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.site.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.site.errors" class="invalid-feedback">
            <div *ngIf="f.site.errors.required">Champ obligatoire.</div>
          </div>
        </div>

        <div
          *ngIf="idSite && site"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <label>Chantier<b style="color: red"> *</b></label>
          <input
            type="text"
            formControlName="site"
            class="form-control input-custom"
            [(ngModel)]="site.name"
            disabled
          />
          <!-- <div *ngIf="submitted && f.site.errors" class="invalid-feedback">
                <div *ngIf="f.site.errors.required">Champ obligatoire.</div>
            </div> -->
        </div>

        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Type de prestation<b style="color: red"> * </b></label>
          <i
            style="cursor: pointer"
            class="bi bi-plus-circle-fill"
            (click)="addType()"
          ></i>
          <ng-select
            (click)="getTypesBenefitsGlass()"
            [items]="typesBenefitGlass"
            bindLabel="type"
            bindValue="_id"
            formControlName="type"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">Champ obligatoire.</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label>Description</label>
          <textarea
            type="text"
            formControlName="description"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
          >
          </textarea>
          <div
            *ngIf="submitted && f.description.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.description.errors.maxlength">
              Ce champ peut comporter 200 caractères maximum.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Prestataire<b style="color: red"> * </b></label>
          <ng-select
            [items]="listSubContractors"
            bindLabel="companyName"
            bindValue="_id"
            formControlName="subContractor"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.subContractor.errors }"
          >
          </ng-select>
          <div
            *ngIf="submitted && f.subContractor.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.subContractor.errors.required">
              Champ obligatoire.
            </div>
          </div>
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label
            >Contrat sous-traitant signé<b style="color: red"> * </b></label
          >
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input type="radio" formControlName="contratSST" [value]="true" />
              Oui
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="contratSST"
                [value]="false"
              />
              Non
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Contact<b style="color: red"> * </b></label>
          <ng-select
            [items]="contacts"
            bindLabel="firstName_lastName"
            bindValue="_id"
            formControlName="contact"
            class="form-control"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
            <div *ngIf="f.contact.errors.required">Champ obligatoire.</div>
          </div>
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Date d’intervention <b style="color: red"> * </b></label>
          <input
            type="date"
            min="{{ d1 }}"
            formControlName="startDate"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }"
          />
          <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
            <div *ngIf="f.startDate.errors.required">Champ obligatoire.</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Fréquence<b style="color: red"> *</b></label>
          <ng-select
            [items]="frequences"
            formControlName="frequence"
            (change)="getFreguence($event)"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.frequence.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.frequence.errors" class="invalid-feedback">
            <div *ngIf="f.frequence.errors.required">Champ obligatoire.</div>
          </div>
        </div>
        <div
          class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          *ngIf="frequenceSelected == 'Hebdomadaire'"
          style="padding-top: 2%"
        >
          <div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox1"
                      value="monday"
                      (change)="chooseDate('Monday')"
                    />
                    <label class="form-check-label" for="myCheckbox1">
                      <span class="checkbox-label">Lundi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox2"
                      (change)="chooseDate('Tuesday')"
                    />
                    <label class="form-check-label" for="myCheckbox2">
                      <span class="checkbox-label">Mardi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox3"
                      (change)="chooseDate('Wednesday')"
                    />
                    <label class="form-check-label" for="myCheckbox3">
                      <span class="checkbox-label">Mercredi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox4"
                      (change)="chooseDate('Thursday')"
                    />
                    <label class="form-check-label" for="myCheckbox4">
                      <span class="checkbox-label">Jeudi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox5"
                      (change)="chooseDate('Friday')"
                    />
                    <label class="form-check-label" for="myCheckbox5">
                      <span class="checkbox-label">Vendredi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox6"
                      (change)="chooseDate('saturday')"
                    />
                    <label class="form-check-label" for="myCheckbox6">
                      <span class="checkbox-label">Samedi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox7"
                      (change)="chooseDate('sunday')"
                    />
                    <label class="form-check-label" for="myCheckbox7">
                      <span class="checkbox-label">Dimanche</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                </div>
                <div
                  style="font-size: 0.875em; color: #f36"
                  *ngIf="validWeeklyBenefitForm == false"
                >
                  Champ obligatoire.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="frequenceSelected == 'Annuelle'"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div>
            <label>Mois <b style="color: red"> *</b></label>
            <ng-select
              [items]="listMonthsannual"
              class="form-control input-custom"
              [closeOnSelect]="true"
              formControlName="listOfMonths"
              *ngIf="registerForm.contains('listOfMonths')"
              (change)="getSelectedMonth($event)"
              [ngClass]="{ 'is-invalid': submitted && f.listOfMonths.errors }"
            >
            </ng-select>
            <div
              *ngIf="submitted && f.listOfMonths.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.listOfMonths.errors.required">
                Champ obligatoire.
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="frequenceSelected == 'Bi-annuelle'"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div>
            <label>Mois <b style="color: red"> *</b></label>
            <ng-select
              (click)="gemothsBiAnnual()"
              [items]="listMonthsannual_Bi_annual"
              bindLabel="name"
              class="form-control input-custom"
              [closeOnSelect]="true"
              formControlName="listOfMonthsBiAnnual"
              multiple="true"
              (change)="getSelectedMonthBiAnnual($event)"
              *ngIf="registerForm.contains('listOfMonthsBiAnnual')"
              [ngClass]="{
                'is-invalid': submitted && f.listOfMonthsBiAnnual.errors
              }"
            >
            </ng-select>
            <div
              *ngIf="submitted && f.listOfMonthsBiAnnual.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.listOfMonthsBiAnnual.errors.required">
                Champ obligatoire.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Dispositif</label>
          <ng-select
            [items]="typesMaterial"
            formControlName="typesMaterial"
            bindLabel="name"
            bindValue="name"
            (click)="initTypeMaterail()"
            class="form-control"
            (change)="recuperateListMaterials($event)"
            [closeOnSelect]="true"
            multiple="true"
          >
          </ng-select>
          <!-- <div *ngIf="submitted && f.typesMaterial.errors" class="invalid-feedback">
                  <div *ngIf="f.typesMaterial.errors.required">Champ obligatoire.</div>
              </div> -->
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Numéro Osiris </label>
          <input
            type="text"
            formControlName="numerosOsiris"
            class="form-control"
          />
          <!-- <div *ngIf="submitted && f.numerosOsiris.errors" class="invalid-feedback">
        <div *ngIf="f.numerosOsiris.errors.required">Champ obligatoire.</div>
    </div> -->
        </div>

        <!-- <div *ngIf="listMaterialsFromHtml.length == 1  && listMaterialsFromHtml[0].name == 'camions'" class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
    <label>Plaque </label>
   <input type="text" formControlName="plaque" class="form-control input-custom"  />
 
</div> -->

        <div
          *ngIf="shouldShowPlaque()"
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div class="row">
            <div>
              <label>Plaque <b style="color: red"> * </b></label>
              <input
                type="text"
                formControlName="plate"
                *ngIf="registerForm.contains('plate')"
                class="form-control input-custom"
                [ngClass]="{ 'is-invalid': submitted && f.plate.errors }"
              />
              <div *ngIf="submitted && f.plate.errors" class="invalid-feedback">
                <div *ngIf="f.plate.errors.required">Champ obligatoire.</div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="shouldShowAttestationControl()"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <label>Attestation de contrôle</label>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="controlCertificate"
                [value]="true"
              />
              Oui
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="controlCertificate"
                [value]="false"
              />
              Non
            </div>
          </div>
        </div>
      </div>

      <div style="padding-top: 3%" class="text-center">
        <button
          (click)="cancel()"
          class="btn btn-secondary mr-4 button_list mb-2"
          type="reset"
        >
          Annuler
        </button>
        <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
<br />

<!-- credits -->
