import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ClientService } from 'src/app/_services/client.service';
import { ContactService } from 'src/app/_services/contact.service';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import codes from 'src/app/BE_Postal_codes.json';
import contries from 'src/app/List_countries.json';
import { PopupAddContactComponent } from '../../popupAdd/popup-add-contact/popup-add-contact.component';
import { PopupAddEtablissementComponent } from '../../popupAdd/popup-add-etablissement/popup-add-etablissement.component';


@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.css','../../../../_shared/add_update.css']
})
export class UpdateClientComponent implements OnInit {

id
client
postalCode: string ="1099";
  city :any 
  registerForm: FormGroup;
  submitted = false;
  city_codePostal : any = ""
  autoliquidationVerif = false
  changeCity = false
  disableEtablissementsInput = false;
  
  contacts :any
  etablissements :any
  worksite = [
    {  name: 'Chantier 1' },
    {  name: 'Chantier 2' },
    {  name: 'Chantier 3' },
   
    
  ]
  contactPrincipalClient

  codes_postales :any = codes 
  list_contries = contries

  etablissementsByclient :any
  contactsByclient :any
  idEtablissementsByclient :any
  idContactsByclient :any
  listContactsByThisClient = []
  codePostalCityName :String = ""
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private clientService :ClientService,
    private contactService :ContactService,
    private etablissementService :EtablissementService,
    public iziToast: Ng2IzitoastService,
    private route :ActivatedRoute ,
    private config: NgSelectConfig ,
    private modalService: NgbModal,
    ) { 
      this.config.notFoundText = "Aucun élement trouvé"
    }

              ngOnInit() {
                this.codes_postales.forEach(item => {
                  item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
                });
                this.id = this.route.snapshot.params["id"];
                  this.clientService.getClientById(this.id).subscribe(data => {
                      this.client = data;
                      this.contactPrincipalClient = this.client.contactMain
                         if(this.client && this.client.contactMain){
                      this.contactPrincipalClient.firstName_lastName = this.client.contactMain["lastName"]+" "+this.client.contactMain["firstName"]
                         }
                         if(this.client.country == "Belgique" && this.client.postalCode){
                          this.codePostalCityName = this.client.postalCode + ' - ' + this.client.city
                          }
                  })
                let city = codes.find(el => el.postal_code === "2060");
                this.registerForm = this.formBuilder.group({
                    companyName: ['', Validators.required],
                    tva: ['', ],
                    autoliquidation: ['', ],
                    country: ['', ],
                    street : ['', ],
                    number : ['', ],
                    postalCode : ['', ],
                    city : ['', ],
                    contact: ['', ],
                    constructionSite: [''],
                    email:['', Validators.email],
                    contactMain: ['',], // principale
                    etablissement : ['', ],
                },);
                this.getContactsNotAffected()
                this.getEtablissement()
                this.getEtablissementsByclient()
                this. getContactsByclient()
              }

             
              getContactsNotAffected() {
              this.contactService.getContacts().subscribe((e) => {
                this.contacts = e;
              });
              }

              getEtablissement() {
              this.etablissementService.getEtablissements().subscribe((e) => {
                this.etablissements = e;
              });
              }

              getEtablissementsByclient(){
                  this.clientService.getEtablissementsByClient(this.id).subscribe(data => {
                    this.etablissementsByclient = data;
                    this.idEtablissementsByclient = this.etablissementsByclient.map(item => item._id);
                    
                  
                })
                }
                getContactsByclient(){
                  this.clientService.getContactsByClient(this.id).subscribe(data => {
                    this.contactsByclient = data;
                    this.listContactsByThisClient = this.contactsByclient
                    this.idContactsByclient = this.contactsByclient.map(item => item._id);
                    if(!this.contacts) {
                      this.contacts = []; // Initialize this.contacts as an empty array if it's not already defined
                    }
                    if(this.contacts.length == 0){
                      this.contacts = this.contactsByclient;
                    } else {
                      this.contacts =  [...this.contacts, ...this.contactsByclient.filter(item2 => !this.contacts.some(item1 => item1._id === item2._id))];
                    }
                  });
                    // if(this.contacts && this.contacts.length == 0){
                    //   this.contacts = this.contactsByclient
                    // }
                    // else{
                    // this.contacts =  [...this.contacts, ...this.contactsByclient.filter(item2 => !this.contacts.some(item1 => item1._id === item2._id))];
                    // }
                //})
              }

              // convenience getter for easy access to form fields
              get f() { return this.registerForm.controls; }

              onSubmit() {
              this.submitted = true;
              if (this.registerForm.invalid) {
                  return;
              }
              if(this.registerForm.value.country === "Belgium" &&this.registerForm.value.postalCode != "" ){
                this.registerForm.value.postalCode = this.registerForm.value.postalCode["postal_code"]
              }
              if(this.registerForm.value.autoliquidation == ""){
                this.registerForm.value.autoliquidation = false
              }
              this.registerForm.value.contact = this.idContactsByclient
              this.registerForm.value.etablissement = this.idEtablissementsByclient
              if(this.registerForm.value.contactMain || this.registerForm.value.contactMain != null){
              this.registerForm.value.contactMain= this.registerForm.value.contactMain._id
              }
             
              const exists = this.registerForm.value.contact.includes(this.registerForm.value.contactMain); 
  
              if(this.registerForm.value.contactMain && !exists ){
                let msg = "Le contact principal choisi n'existe pas dans la liste des contacts de ce client."
                this.iziToast.show({
                  message: msg,
                  messageColor:'#800f2f',
                  titleColor:'#800f2f',
                  progressBarColor:'#c9184a',
                  icon:'bi-exclamation-diamond',
                  imageWidth:45,
                  position:'topRight',
                  timeout:5000,
                  backgroundColor:'#ff8fa3',
                  transitionIn: 'flipInX',
                  transitionOut: 'flipOutX',
                  overlay:true,
                  overlayClose:true,
                  
                });
                this.registerForm.patchValue({
                  contactMain: '' // Update contactMain to an empty string
                });
                return;
              }
                this.clientService.editContact(this.id,this.registerForm.value).subscribe(
                  (data) => {
                  
                    
                    this.iziToast.show({
                      message:'Modification avec succès',
                      messageColor:'#386641',
                      progressBarColor:'#6a994e',
                      icon:'bi-check-circle',
                      imageWidth:45,
                      position:'topRight',
                      timeout:5000,
                      backgroundColor:'#dde5b6',
                      transitionIn: 'flipInX',
                      transitionOut: 'flipOutX',
                       });
                      this.router.navigate(['/admin/clients' ])
                      })
              }

              onReset() {
              }


              getCity()
              {
                this.changeCity = true
              if(this.registerForm.value.postalCode != null)
              {
              this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
                  this.registerForm.value.city = this.city
                  this.city_codePostal = this.city["place_name"];
              }
              else{
                this.city_codePostal = ""
              }

              }

              getNewcontats()
              {
                this.idContactsByclient = this.registerForm.value.contact.map(item => item._id);
                this.listContactsByThisClient = this.registerForm.value.contact
                if(this.listContactsByThisClient.length == 1){
                  this.contactPrincipalClient =this.listContactsByThisClient[0] 
                }
                else{
                  this.contactPrincipalClient = undefined
                }
                

              }


              getNewEtablissemenst()
              {
                this.idEtablissementsByclient = this.registerForm.value.etablissement.map(item => item._id);
              }

              addContact(){
                let modalRef
                modalRef =this.modalService.open(PopupAddContactComponent);
                modalRef.componentInstance.client = null;
                }
              
                addEtablissement(){
                  let modalRef
                  modalRef =this.modalService.open(PopupAddEtablissementComponent);
                }


            
}

