import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BenefitService {

  private _benefitURL=`${environment.baseUrl}/benefits`

  constructor(private http: HttpClient) { }

      getAllBenefits():Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/allBenefits`);
      }   
      getListArchivedBenefits() :Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listArchivedBenefits`);
      }   

      createBenefit(benefit: any):Observable<Object> {
        return this.http.post(`${this._benefitURL}`,benefit);
      }

      getBenefitsBysite(idSite : any):Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listBenefits-by-site/${idSite}`);
      } 

        //Occurence à mofifer ca dans le js des occcurences des que il va creer
      getOccurrencesByBenefit(idBenefit : any):Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listOccurrence-by-benefit/${idBenefit}`);
      } 

      // update occurence
      EditOccurence(id:string,occurence:any):Observable<Object> {
        return this.http.put(`${this._benefitURL}/updateOccurence/${id}`,occurence);
      }


      //calendar
      getOccurrences_calendar_ByBenefit(idBenefit : any):Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/listOccurrence-calendar-by-benefit/${idBenefit}`);
      } 


      //// list Sites By subContractor (a travers benefits)
      getList_Sites_by_subContractor(idSubContrators : any):Observable<any>
      {
        return this.http.get<any>(`${this._benefitURL}/list_Sites_by_subContractor/${idSubContrators}`);
      } 

    //// list Sites By subContractor (a travers benefits)
    getList_Sites_by_Worker(idWorker : any):Observable<any>
    {
      return this.http.get<any>(`${this._benefitURL}/list_Sites_by_worker/${idWorker}`);
    }  

    //get détails benefit (details is color and name)
    getDetailsBenefit(idBnefit : any):Observable<any>
    {
      return this.http.get<any>(`${this._benefitURL}/details-benefit/${idBnefit}`);
    }  

    // agenda
    getOccurrences(){
      return this.http.get<any>(`${this._benefitURL}/get/all/occurrences`);
    }

    // agenda
    getWorkersByBenefit(idBnefit : any){
      return this.http.get<any>(`${this._benefitURL}/getWorkers/By-benefit/${idBnefit}`);
    }

    //export xls benefitswithOccrence
    getbenefitsWithOccrences(idSite :any, typeBnefit :any, frequency :any ,startDate :any ,endDate :any, subContractor :any):Observable<any>
    {
      return this.http.get<any>(`${this._benefitURL}/list-benfits-Occurrences-XLS/${idSite}/${typeBnefit}/${frequency}/${startDate}/${endDate}/${subContractor}`);
    } 



    // le 1 fevirer 2024 les updates
    updateDurationBenefit(idBnefit,benefit){
      return this.http.put<any>(`${this._benefitURL}/update-duration-benefit/${idBnefit}`,benefit);   
     }


     //edit benefit
     editDataBenefit(idBnefit,benefit){
      return this.http.put<any>(`${this._benefitURL}/${idBnefit}`,benefit);   
     }


}
