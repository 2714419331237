import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { BenefitService } from 'src/app/_services/benefit.service';
import { SiteService } from 'src/app/_services/site.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { TypebenefitService } from 'src/app/_services/typebenefit.service';
import { PopupAddTypeBenefitComponent } from '../../popupAdd/popup-add-type-benefit/popup-add-type-benefit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkerBySubContractorForBenefitService } from 'src/app/_services/worker-by-sub-contractor-for-benefit.service';

@Component({
  selector: 'app-update-benefit',
  templateUrl: './update-benefit.component.html',
  styleUrls: ['./update-benefit.component.css','../../../../_shared/add_update.css']
})
export class UpdateBenefitComponent implements OnInit {
//   typesBenefit = ["Alus ascenseurs","Avaloirs","Cafétéria","Cage d’escaliers","Caves/locaux techniques","Consommables","Cour intérieure","Démoussage",
//   "Dépoussiérage en hauteur","Douches","Escalier de secours","Extracteurs/grilles","Local poubelles","Local vélos","Luminaires",
//  "Matériel IT","Nettoyage","Parking autolaveuse","Parking balayage","Portes","Portes de garage","Sanitaires","Stores","Terrasses","Toiture",
//  "Vacant","Ventelles métalliques","Volets extérieurs","Autre"]
typesBenefit
frequences =["Quotidienne","Hebdomadaire","Tous les 15 jours","Mensuelle","Mois pairs","Mois impairs",
  "Trimestrielle 1-4-7-10","Trimestrielle 2-5-8-11","Trimestrielle 3-6-9-12","Quadrimestrielle","Semestrielle","Bi-annuelle","Annuelle"]
  listMonthsannual =["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]

  listMonthsannual_Bi_annual = []


  monthChoised_frenquency_annuelle :String
  monthsChoised_frenquency_BiAnnuelle =[]
  id: any;
  benefit: any;
  registerForm: FormGroup;
  submitted = false;
  d1: any;
  dateAuj = new Date();
  sites: any;
  workers: any;
  listSubContractors: any;
  //workersSelectedChoise :any
 // idIntialSubContractor :any 
  frequenceSelected: any;

  datesChooses = []
  monthChooses = []
  MonthSelectedforFrequenceAnual: number;

 

  selectedMonthsBiAnnual : string[] = [];
  MonthSelectedforFrequenceBi_annual :any
  listDates= [];
  hebdates: any;
    /*weekly*/
    dayToNumber = {
      Monday: "01",
      Tuesday: "02",
      Wednesday: "03",
      Thursday: "04",
      Friday: "05",
      saturday:"06",
      sunday:"07"
    };
    /*weekly*/
  validWeeklyBenefitForm: boolean;
  initialfrenquency: any;
  changeStatut: boolean = false;

  subContractorsByBenfit : any =[] 
  subContractorsWithWorkers: any;
  objetsAvecIds: any[];

  workersSelcted = []

  constructor(
    private benefitService :BenefitService,
    private route :ActivatedRoute,
    private formBuilder: FormBuilder,
    private router :Router,
    private siteService :SiteService,
    private subContractorService : SubContractorService,
    public iziToast: Ng2IzitoastService,
    private typeBenefitService :TypebenefitService,
    private modalService: NgbModal,
    private workerBySubContractorForBenefitService :WorkerBySubContractorForBenefitService


  ) { }

  ngOnInit(): void {
   

    this.id = this.route.snapshot.params["id"];
    this.benefitService.getDetailsBenefit(this.id).subscribe(data => {
      this.benefit = data;
      //this.workersSelectedChoise = this.benefit.worker
      //this.idIntialSubContractor = this.benefit.subContractor._id
      this.initialfrenquency = this.benefit.frequence
      this.frequenceSelected = this.benefit.frequence
     if(this.benefit.frequence == "Bi-annuelle" ){
      
      for (let mo of this.benefit.listMonths){
      var indice = mo;
     var indiceDecimal = parseInt(indice, 10);
     var month = this.listMonthsannual[indiceDecimal-1];
    this.monthsChoised_frenquency_BiAnnuelle.push(month)
      }
     }
     if(this.benefit.frequence == "Annuelle" ){
      var indice = this.benefit.listMonths;
     var indiceDecimal = parseInt(indice, 10);
     var month = this.listMonthsannual[indiceDecimal-1];
    this.monthChoised_frenquency_annuelle = month
     }
      if(this.benefit.worker && this.benefit.worker.length >0){
      this.benefit.worker.forEach(item => {
        item.firstName_lastName = item.firstName +" "+ item.lastName;
    });
  }
    
  })
  this.registerForm = this.formBuilder.group({
    name: ['', Validators.required],
    startDate:['', Validators.required],
    endDate : ['', ],
    frequence : ['', Validators.required],
    listMonths : ['', ],
    listDates : ['', ],
    note: ['', Validators.maxLength(200)], 
    statut : ['', ],
    site : [ , Validators.required],
    color : ['', ], // ignorer required
    subContractor :[, Validators.required],
    contratSubContracor :[false,],
    worker :[],
    duration :[''],
    SubContractorswithWorkers :[]

      },);


      this.registerForm.get('frequence').valueChanges.subscribe((option: string) => {
        this.updateFormControls(option);
      });
        // Écouter les changements dans le champ "Sous-traitant"
        this.registerForm.get('subContractor').valueChanges.subscribe(() => {
          // Réinitialiser le champ "Ouvrier(s)" lorsque le "Sous-traitant" change
          this.registerForm.get('worker').setValue(null);
        });
      this.d1 = this.dateAuj.toISOString().substr(0, 10);
      this.getSubContratcors()
      this.getSubContractorsByBenefit()
      this.getSites()
      this.getTypesBenefits()




}
getSites() {
  this.siteService.getSites_not_Stopedt().subscribe((e) => {
    this.sites = e;
    this.sites.forEach(item => {
      item.name = item.column3;
  }); 
  });
}
getSubContratcors()
{
  this.subContractorService.getList_subContractor().subscribe(
    (data) => {
        this.listSubContractors = data
        })
}
getSubContractorsByBenefit() {
  this.workerBySubContractorForBenefitService.list_workers_subcontartorsBybenefit(this.id).subscribe(
    (data) => {
      this.subContractorsWithWorkers = data
      this.subContractorsByBenfit = data.map(subContractor => ({
        _id: subContractor.subContractor._id,
        companyName: subContractor.subContractor.companyName
      }));
    }
  );
}

getWorkersBySubContractor(idSubContractor)
{
  this.subContractorService.getWorkersBySub_contractor(idSubContractor).subscribe(
    (data) => {
        this.workers = data
      if(this.benefit.subContractor.find(x=>x._id ===idSubContractor)){
        let subContractorSerachedwithWorkers = this.subContractorsWithWorkers.find(x=>x.subContractor._id ===idSubContractor)
        if(subContractorSerachedwithWorkers && subContractorSerachedwithWorkers.workers && subContractorSerachedwithWorkers.workers.length > 0){
          let workerIds = subContractorSerachedwithWorkers.workers.map(worker => worker._id);
          this.workers = this.workers.filter(worker => !workerIds.includes(worker._id));
        }
      }

        })
}

getTypesBenefits(){
  
  this.typeBenefitService.getTypesBenefits().subscribe(
    (data) => {
        this.typesBenefit = data
        })
}
get f() { return this.registerForm.controls; }
private updateFormControls(option: string): void {
  if (option === 'Annuelle') {
    this.registerForm.addControl('listOfMonths', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonthsBiAnnual');
  
  }
  else if (option === 'Bi-annuelle') {
    this.registerForm.addControl('listOfMonthsBiAnnual', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonths');
  
  }
}

getFreguence(frequence ) {
  this.frequenceSelected = frequence;
      }



onSubmit() {
 
    this.submitted = true;
    if (this.registerForm.invalid) {     
        return;
    }
    let currentDate = new Date(this.registerForm.value.startDate);
    currentDate.setFullYear(currentDate.getFullYear() + 2);
    var endDate = currentDate.toISOString().slice(0, 10);
    this.registerForm.value.endDate = endDate 

    let parts = this.registerForm.value.startDate.split('-');
    let month = parseInt(parts[1]);
    let dayOfMotnth = parseInt(parts[2]); 

    if(this.registerForm.value.frequence == "Mensuelle"){
      this.datesChooses =[]
      let day = parseInt(parts[2]).toString();
      if(day.length == 1 ){
         day ="0"+day
      }
      this.datesChooses.shift();
      this.datesChooses.push(day)
      }
      if(this.registerForm.value.frequence == "Tous les 15 jours"){
        let day = parseInt(parts[2]).toString();
        let month =parseInt(parts[1])
        if(day.length == 1 ){
           day ="0"+day
        }
        this.datesChooses.shift();
        this.datesChooses.push(day)
        this.monthChooses.shift();
        this.monthChooses.push(month)
        this.registerForm.value.listMonths = this.monthChooses 
        }
  
    let searchWord = "Trimestrielle"
    if ( (this.registerForm.value.frequence.indexOf(searchWord) !== -1)){   
      //month = month + 1

      // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
      //   month = month - 1
      // }
      this.datesChooses =[]
      if(month == 13){
        month = 1
      }
      this.monthChooses.push(month);
      for (let i = 1; i < 4; i++) {
        month += 3;
        if (month > 12) {
          month = month - 12;
        }
        this.monthChooses.push(month);
        this.datesChooses =["01","01","01","01"] 
       }
       this.registerForm.value.listMonths = this.monthChooses 
      }

      if ( (this.registerForm.value.frequence== "Mois pairs")){   
        if(month %2 !=0)
          month=month+1
        if(month == 13){
          month = 1
        }
        this.monthChooses.push(month);
        for (let i = 1; i < 6; i++) {
          month += 2;
          if (month > 12) {
            month = month - 12;
          }
          this.monthChooses.push(month);
          this.datesChooses =["01","01","01","01","01","01"] 
         }
         this.registerForm.value.listMonths = this.monthChooses 
        }
      
      if ( (this.registerForm.value.frequence== "Mois impairs")){   
          if(month %2 ==0)
            month=month+1
          if(month == 13){
            month = 1
          }
          this.monthChooses.push(month);
          for (let i = 1; i < 6; i++) {
            month += 2;
            if (month > 12) {
              month = month - 12;
            }
            this.monthChooses.push(month);
            this.datesChooses =["01","01","01","01","01","01"] 
           }
           this.registerForm.value.listMonths = this.monthChooses 
          } 

      if(this.registerForm.value.frequence == "Quadrimestrielle"){
        //month = month + 1
        // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
        //   month = month - 1
        // }
        this.datesChooses =[]
        if(month == 13){
          month = 1
        }
         
        
        this.monthChooses.push(month);
        for (let i = 1; i < 3; i++) {
          month += 4;
          if (month > 12) {
            month = month - 12;
        }
          this.monthChooses.push(month);
          this.datesChooses =["01","01","01"] 
      }
      this.registerForm.value.listMonths = this.monthChooses 
    }


    if(this.registerForm.value.frequence == "Hebdomadaire"){
      if(!this.hebdates && this.initialfrenquency == "Hebdomadaire" ){
        this.hebdates = this.benefit.listDates
      }
      if(this.hebdates == undefined || this.hebdates.length == 0){
        this.validWeeklyBenefitForm = false
        return;
      }
    }


    if(this.registerForm.value.frequence == "Hebdomadaire"){
      this.datesChooses = this.hebdates
    }

    if(this.registerForm.value.frequence == "Quotidienne"){
      this.datesChooses =[]
      this.datesChooses = ["01","02","03","04","05"]
    }

    if(this.registerForm.value.frequence == "Semestrielle"){
      this.datesChooses =[]
      //month = month + 1
      // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
      //   month = month - 1
      // }
      if(month == 13){
        month = 1
      }
      this.monthChooses.push(month);

      for (let i = 1; i < 2; i++) {
        month += 6;
        if (month > 12) {
          month = month - 12;
      }
        this.monthChooses.push(month);
        this.datesChooses =["01","01"] 
      }
      this.registerForm.value.listMonths = this.monthChooses 
      }

      if(this.registerForm.value.frequence == "Annuelle"){
        this.datesChooses =[]
        this.monthChooses.push(this.MonthSelectedforFrequenceAnual)
        this.registerForm.value.listMonths = this.monthChooses
        this.datesChooses =["01"]  
      }

      if(this.registerForm.value.frequence == "Bi-annuelle"){
       
        this.datesChooses =[]
        if(!this.MonthSelectedforFrequenceBi_annual){
          this.MonthSelectedforFrequenceBi_annual = this.benefit.listMonths.map(month => parseInt(month, 10));
          // this.MonthSelectedforFrequenceBi_annual = this.benefit.listMonths
        }
       
        if(this.MonthSelectedforFrequenceBi_annual.length == 2)
        {
          this.registerForm.value.listMonths = this.MonthSelectedforFrequenceBi_annual
          this.datesChooses =["01","01"]   
        }
        else{
          this.iziToast.show({
            message:"Merci de choisir deux mois pour la fréquence Bi-annuelle.",
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
          return false
        }
      }

   

    this.registerForm.value.listDates = this.datesChooses 
   let convertedlistMonths =[]
 
   if(this.registerForm.value.listMonths[0] == undefined){
    this.registerForm.value.listMonths =  this.benefit.listMonths
   }
  for(let month of this.registerForm.value.listMonths){
    if(month < 10){
      if(month.toString().length < 2)
      month = "0"+month
      convertedlistMonths.push(month)
    }
    else{
      convertedlistMonths.push(month.toString())
    }
  }

  this.registerForm.value.listMonths = convertedlistMonths
    this.registerForm.value.site = this.registerForm.value.site._id
    const ids = this.registerForm.value.subContractor.map(x => x._id);
    this.registerForm.value.subContractor = ids
    this.registerForm.value.name = this.registerForm.value.name._id
    if(this.registerForm.value.worker){
      let listworkerWithIds = this.registerForm.value.worker.map(item => item._id);
        this.registerForm.value.worker = listworkerWithIds
      }
       let formattedList = this.subContractorsWithWorkers.map(item => ({
        id_subcontractor: item.subContractor._id,
        ids_workers: item.workers.map(worker => worker._id)
    }));
    
    this.registerForm.value.SubContractorswithWorkers = formattedList
    this.benefitService.editDataBenefit(this.id,this.registerForm.value).subscribe(
      (data) => {
        this.router.navigate(['/admin/chantiers/details/'+this.registerForm.value.site ])
        //this.sharedService.setIdSiteSendTOBenefit("");
           this.iziToast.show({
            message: "Modification avec succès",
            messageColor: "#386641",
            progressBarColor: "#6a994e",
            icon: "bi-check-circle",
            imageWidth: 45,
            position: "topRight",
            timeout: 5000,
            backgroundColor: "#dde5b6",
            transitionIn: "flipInX",
            transitionOut: "flipOutX",
          });
          }
          )
}

// CheckIntialsubContractor(subContractor){
//   if(subContractor._id === this.idIntialSubContractor){
//     this.workersSelectedChoise = this.benefit.worker
//   }
//   else{
//     this.workersSelectedChoise = []
//   }
// }
gemothsBiAnnual() {
  this.listMonthsannual_Bi_annual = [
    { name: 'Janvier', disabled: false },
    { name: 'Février', disabled: false },
    { name: 'Mars', disabled: false },
    { name: 'Avril', disabled: false },
    { name: 'Mai', disabled: false },
    { name: 'Juin', disabled: false },
    { name: 'Juillet', disabled: false },
    { name: 'Août', disabled: false },
    { name: 'Septembre', disabled: false },
    { name: 'Octobre', disabled: false },
    { name: 'Novembre', disabled: false },
    { name: 'Décembre', disabled: false },
  ];
  // if(this.benefit.frequence == "Bi-annuelle")
 
  //this.monthsChoised_frenquency_BiAnnuelle.pop()
 // this.monthsChoised_frenquency_BiAnnuelle =   []
  if (this.selectedMonthsBiAnnual.length > 2){
    this.monthsChoised_frenquency_BiAnnuelle=[]
    this.selectedMonthsBiAnnual =[]
    this.listMonthsannual_Bi_annual = [
      { name: 'Janvier', disabled: false },
      { name: 'Février', disabled: false },
      { name: 'Mars', disabled: false },
      { name: 'Avril', disabled: false },
      { name: 'Mai', disabled: false },
      { name: 'Juin', disabled: false },
      { name: 'Juillet', disabled: false },
      { name: 'Août', disabled: false },
      { name: 'Septembre', disabled: false },
      { name: 'Octobre', disabled: false },
      { name: 'Novembre', disabled: false },
      { name: 'Décembre', disabled: false },
    ];
  }  


  if (this.selectedMonthsBiAnnual.length >= 2) {
    const selectedMonthNames = this.selectedMonthsBiAnnual.map((month) => month['name']);

    this.listMonthsannual_Bi_annual = this.listMonthsannual_Bi_annual.map((month) => {
      if (!selectedMonthNames.includes(month.name)) {
        return { ...month, disabled: true };
      }
      return month;
    });
  }
}

getSelectedMonthBiAnnual(event: any) {
  this.selectedMonthsBiAnnual = event;
  let monthIndex1
  let monthIndex2
  let listMonths =[]
  if(this.selectedMonthsBiAnnual[0]){
  monthIndex1  = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[0]['name']);
  listMonths.push(monthIndex1+1)
  }
  if(this.selectedMonthsBiAnnual[1]){
   monthIndex2 = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[1]['name']);
   listMonths.push(monthIndex2+1)
  }
   this.MonthSelectedforFrequenceBi_annual = listMonths
}

      //Hebdomadaire 
      chooseDate(value){
        if(this.initialfrenquency != "Hebdomadaire"){
        let index = this.listDates.indexOf(value)
        if(index == -1){
        this.listDates.push(value)
        }
        else{
        this.listDates.splice(index,1)
        }
        const numbersList = this.listDates.map(day => this.dayToNumber[day]);
        this.hebdates=numbersList
        this.hebdates.sort(function(a, b) {
          return a - b;
      });
        if(this.hebdates.length > 0){
          this.validWeeklyBenefitForm = true
        }
      }
      else
      {
        this.listDates = this.convertToDays(this.benefit.listDates,this.dayToNumber)
        if(!this.listDates.includes(value)){
          this.listDates.push(value)
          var golocaleValue = this.dayToNumber[value];
          this.benefit.listDates.push(golocaleValue);
        }
        else{
          const index = this.listDates.indexOf(value);
          if (index !== -1) {
            // Remove "Sunday" from the array
            this.listDates.splice(index, 1);
            this.benefit.listDates.splice(index, 1);
          }
          //this.listDates = this.listDates.filter(day => day !== value);
        }
        const numbersList = this.listDates.map(day => this.dayToNumber[day]);
        this.hebdates=numbersList
        this.hebdates.sort(function(a, b) {
          return a - b;
      });
        if(this.hebdates.length > 0){
          this.validWeeklyBenefitForm = true
        }

        }
      }

      getSelectedMonth(value)
      {
      // this.selectedMonth =value
        const monthIndex = this.listMonthsannual.indexOf(value)+1;
        this.MonthSelectedforFrequenceAnual = monthIndex
        //this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      }



convertListeEnJours(liste){
  const joursDeLaSemaine = [];
  liste.forEach(nombre => {
    // Recherche de la clé correspondant au nombre dans dayToNumber
    const jour = Object.keys(this.dayToNumber).find(key => this.dayToNumber[key] === nombre);
    // Si la clé est trouvée, ajouter le jour à la liste des jours
    if (jour) {
      joursDeLaSemaine.push(jour);
    }
  });
  return joursDeLaSemaine;
}


convertToDays(numbersArray, mapping) {
  const daysArray = [];
  numbersArray.forEach(number => {
    const day = Object.keys(mapping).find(key => mapping[key] === number);
    if (day) {
      daysArray.push(day);
    } else {
      daysArray.push("Invalid");
    }
  });
  return daysArray;
}

rechoiseMonths_frequency_Bi_Annual(){
  this.changeStatut =true
  this.gemothsBiAnnual()
}

addType(){
  let modalRef
  modalRef =this.modalService.open(PopupAddTypeBenefitComponent);
  // if(this.registerForm.value.etablissement)
  // modalRef.componentInstance.etab = this.registerForm.value.etablissement;
//this.modalService.open(PopupAddClientComponent);
}

add2YearsToStartDate(date)
{
  let currentDate = new Date(date);
    currentDate.setFullYear(currentDate.getFullYear() + 2);
    var endDate = currentDate.toISOString().slice(0, 10);
    this.benefit.endDate = endDate
}


createListSubcontractors()

{
  this.subContractorsByBenfit.forEach(subContractor => {
    const existsInList1 = this.subContractorsWithWorkers.some(item => item.subContractor && item.subContractor._id === subContractor._id);
    if (!existsInList1) {
      this.subContractorsWithWorkers.push({
        subContractor: {
          _id: subContractor._id,
          companyName: subContractor.companyName
        },
        workers: []
      });
    }
  });
    this.subContractorsWithWorkers = this.subContractorsWithWorkers.filter(item =>
    this.subContractorsByBenfit.some(subContractor => subContractor._id === item.subContractor._id)
  );
}
createListWorkersBySubContractors(idSubcontrator, worker) {
  let subcontractordSearched = this.subContractorsWithWorkers.find(x => x.subContractor._id === idSubcontrator);

  for (let wor of worker) {
    let exists = subcontractordSearched.workers.some(existingWorker => existingWorker._id === wor._id);
    if (!exists) {
      subcontractordSearched.workers.push(wor);
    }
  }
}


deleteWorkerFromList(idSubcontractor, worker) {
  let subcontractorSearched = this.subContractorsWithWorkers.find(x => x.subContractor._id === idSubcontractor);

  if (subcontractorSearched) {
    subcontractorSearched.workers = subcontractorSearched.workers.filter(w => w._id !== worker._id);
  } 
}

onRemove(idSubcontractor,idWorker: any) {
  let subcontractorSearched = this.subContractorsWithWorkers.find(x => x.subContractor._id === idSubcontractor);
  subcontractorSearched.workers = subcontractorSearched.workers.filter(w => w._id !== idWorker)

}


}
