import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypebenefitGlassService {

  private _typeBenefitGlassURL=`${environment.baseUrl}/typesBenefitsGlass`
  constructor(private http: HttpClient) { }

     getTypesBenefitsGlass() :Observable <any> {
      return this.http.get(`${this._typeBenefitGlassURL}`);
      }

      createTypeBenefitGlass(typeBenefitGlass: any):Observable<Object> {
        return this.http.post(`${this._typeBenefitGlassURL}`,typeBenefitGlass);
      }

      getTypeBenefitGlassById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._typeBenefitGlassURL}/${id}`);
    
      } 
      
      EditTypeBenefiGlasst(id:string,TypeBenefitGlass:any):Observable<Object> {
        return this.http.put(`${this._typeBenefitGlassURL}/${id}`,TypeBenefitGlass);
      }
     
}
