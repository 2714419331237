import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WorkerBySubContractorForBenefitService {

  private _worker_subcontartorBybenefit=`${environment.baseUrl}/workers_subContrators`

  constructor(private http: HttpClient) { }

     list_workers_subcontartorsBybenefit(idBnefit){
      return this.http.get<any>(`${this._worker_subcontartorBybenefit}/list_subcontractorsAndWorkers/byBenefit/${idBnefit}`);   
     }

     listWorkersBybenfit(idBnefit){
      return this.http.get<any>(`${this._worker_subcontartorBybenefit}/listWorkers/byBenefit/${idBnefit}`);   
     }
    
}
