<app-navbar></app-navbar>
<br />
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li
        class="breadcrumb-item active color-blue"
        style="cursor: pointer"
        routerLink="/admin/prestations"
      >
        Prestations
      </li>
      <li class="breadcrumb-item active color-blue" style="cursor: default">
        Types des prestations
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%">
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1">
            Liste des types des prestations
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
        <!-- Actions -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mt-4 text-sm-end">
          <div class="mx-n1">
            <a
              (click)="addType()"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
              <span class="button_list" style="color: #fff"
                >Créer un type de prestation</span
              >
            </a>
          </div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0"></ul>
    </div>
  </div>
</header>
<main class="py-6 bg-surface-secondary">
  <div class="container-fluid" style="width: 86%">
    <br />
    <div class="card shadow border-0 mb-7">
      <div class="card-header col-12">
        <div class="row">
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 class="titleotherpage" (click)="goToListBenifits()">
              Prestations
            </h4>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 class="titlepage">Types des prestations</h4>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
            <h4 class="titleotherpage" (click)="goToListbenfitsArchiveds()">
              Prestations archivées
            </h4>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <div
          class="form-outline col-xl-6 col-6-cust col-lg-6 col-md-12 col-sm-12"
        >
          <label class="form-label" for="form1">Recherche</label>
          <input
            type="search"
            id="form1"
            class="form-control"
            #myInput
            [(ngModel)]="type"
            (input)="search()"
          />
        </div>
      </div>

      <div
        [ngClass]="sizeContent > 4 ? 'scroller' : 'scroller2'"
        class="table-responsive"
        *ngIf="typesBenefits"
      >
        <table class="table">
          <thead>
            <tr
              style="
                border-bottom: 1px solid #e7eaf0;
                border-top-color: transparent;
              "
            >
              <th style="cursor: pointer" class="text-center">
                Type de prestation
                <!-- <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col2 == true" class="bi bi-chevron-up"></i> -->
              </th>
              <th class="text-center">actions</th>
            </tr>
          </thead>
          <tbody
            *ngFor="
              let i of typesBenefits | customerTypeFilter : type;
              let k = index
            "
          >
            <tr
              *ngIf="k % 2 != 0"
              style="background-color: #e9ebff; border: hidden"
            >
              <td class="text-center">{{ i.type }}</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                >
                  <i
                    class="bi bi-pencil-square"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Modifier"
                    (click)="updateType(i._id)"
                  ></i>
                </button>
              </td>
            </tr>
            <tr
              *ngIf="k % 2 == 0"
              style="background-color: white; border-bottom: hidden"
            >
              <td class="text-center">{{ i.type }}</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2"
                >
                  <i
                    class="bi bi-pencil-square"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Modifier"
                    (click)="updateType(i._id)"
                  ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="typesBenefits && typesBenefits.length == 0"
          class="table-responsive"
          style="text-align: center"
        >
          <p style="padding-bottom: 45px; padding-top: 35px">
            Aucun type de prestation n'est trouvée
          </p>
        </div>
      </div>
    </div>
  </div>
</main>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-pulse"
>
  <p style="font-size: 20px; color: white">Veuillez patienter ...</p>
</ngx-spinner>
