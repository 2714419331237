import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import codes from "src/app/BE_Postal_codes.json";
import { EtablissementService } from "src/app/_services/etablissement.service";
import { ClientService } from "src/app/_services/client.service";
import { Ng2IzitoastService } from "ng2-izitoast";
import { UserService } from "src/app/_services/user.service";
import { PopupAddClientComponent } from "../../popupAdd/popup-add-client/popup-add-client.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventServiceService } from "src/app/_services/event-service.service";

@Component({
  selector: "app-add-etablissement",
  templateUrl: "./add-etablissement.component.html",
  styleUrls: [
    "./add-etablissement.component.css",
    "../../../../_shared/add_update.css",
  ],
})
export class AddEtablissementComponent implements OnInit {
  clients: any;
  admins :any
  clients_copy: any = [];
  registerForm: FormGroup;
  submitted = false;
  listNamesClients: any = [];
  codes_postales :any = codes;
  city: any;
  city_codePostal: string;
  list_managers = ["Solenn", "Karen", "Valérie"];
  clientf =[]
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private etbalissementService: EtablissementService,
    private clientService: ClientService,
    private userService: UserService,
    public iziToast: Ng2IzitoastService,
    private modalService: NgbModal,
    private eventService: EventServiceService

  ) {}

  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    this.eventService.launchEvent.subscribe(() => {
      this.getClients();
      this.setValut()
      
    });
       
    
  
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      adress: this.formBuilder.array([]),
      manager: ["", Validators.required],
      client: [""],
      site: [""],
    });
    this.adress().push(
      this.formBuilder.group({
        street: "",
        number: "",
        postalCode: "",
        city: "",
      })
    );
    //this.getClients();
    this.getUsers();
    
  }
  setValut() {
    this.clientService.getClients().subscribe((e) => {
      this.clients = e;
      this.clientf = this.registerForm.value.client;
      // Initialize this.clientf as an array
      if (!Array.isArray(this.clientf)) {
        this.clientf = [];
      }
      let foundClient = this.clients.find(
        (company) =>
          company.companyName ===
          localStorage.getItem('clientAdedInEtablissement')
      );
      if (foundClient) {
        let clientObj = {
          _id: foundClient._id,
          companyName: foundClient.companyName,
          tva: foundClient.tva,
          autoliquidation: foundClient.autoliquidation,
          country: foundClient.country,
          street: foundClient.street,
          number: foundClient.number,
          postalCode: foundClient.postalCode,
          city: foundClient.city,
          contactMain: foundClient.contactMain,
          email: foundClient.email,
          archive: foundClient.archive,
          createdAt: foundClient.createdAt,
          updatedAt: foundClient.updatedAt,
        };
        this.clientf.push(clientObj);
        this.registerForm.get('client').patchValue(this.clientf);
        this.setListNamesClients();
      }
    });
  }
  
  getClients() {
    this.clientService.getClients().subscribe((e) => {
      this.clients = e;
    }); 
  
  }

  getUsers() {
    this.userService.getListAdmin().subscribe((e) => {
      this.admins = e;
    });
  }

  adress(): FormArray {
    return this.registerForm.get("adress") as FormArray;
  }

  addQuantity() {
    this.adress().push(this.newadress());
  }
  removeQuantity(i: number) {
    this.adress().removeAt(i);
  }
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    if (!this.registerForm.value.adress[0].street) {
      this.iziToast.show({
        message: "Veuillez vérifier les données de la première adresse",
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
      });

      return false;
    }
    if (!this.registerForm.value.adress[0].postalCode) {
      this.iziToast.show({
        message: "Veuillez vérifier les données de la première adresse",
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
      });

      return false;
    }
    if (!this.registerForm.value.adress[0].number) {
      this.iziToast.show({
        message: "Veuillez vérifier les données de la première adresse",
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
      });

      return false;
    }
    for (let i = 0; i < this.registerForm.value.adress.length; i++) {
      let city = "";
      city = this.registerForm.value.adress[i].postalCode.place_name;
      this.registerForm.value.adress[i].postalCode =
        this.registerForm.value.adress[i].postalCode["postal_code"];
      this.registerForm.value.adress[i].city = city;
      if(!this.registerForm.value.adress[i].city && this.registerForm.value.adress[i].city == "" || this.registerForm.value.adress[i].city == null){
      this.registerForm.value.adress[i].city = ""
      }
      if(!this.registerForm.value.adress[i].postalCode && this.registerForm.value.adress[i].postalCode == "" || this.registerForm.value.adress[i].postalCode == null){
      this.registerForm.value.adress[i].postalCode = ""
      }
    }
    // if (!this.registerForm.value.client) {
    //   this.registerForm.value.client = ["000000000000000000000000"];
    // }

    let clientSiteSelected = [];
if(this.clients_copy && this.clients_copy.length >0){
    for (let client of this.clients_copy) {
      if (client) {
        let arrayTest = [];
        arrayTest = client.siteSelected;
        for (let site of arrayTest) {
          clientSiteSelected.push(site._id);
        }
      }
    }
  }
    this.registerForm.value.site = clientSiteSelected;
    this.etbalissementService
      .createEtablissements(this.registerForm.value)
      .subscribe((data) => {
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.router.navigate(["/admin/etablissements"]);
      });
  }

  getCity(i) {
    let city;
    city = codes.find(
      (el) =>
        el.postal_code ===
        this.registerForm.value.adress[i].postalCode.postal_code
    );
  }

  newadress(): FormGroup {
    return this.formBuilder.group({
      street: "",
      number: "",
      postalCode: "",
      city: "",
    });
  }
  setListNamesClients() {
        for (let i = 0; i < this.registerForm.value.client.length; i++) {
       const obj = this.clients.find((obj) => obj._id ===  this.registerForm.value.client[i]._id);
       let exists = this.listNamesClients.find(item =>item._id.toString() === obj._id.toString());
       if (obj  && !exists) {
        this.listNamesClients.push(obj)
        }
     }
     if(this.registerForm.value.client[this.registerForm.value.client.length-1] && this.registerForm.value.client[this.registerForm.value.client.length-1] != undefined){
      this.clientService.getsiteByClient(this.registerForm.value.client[this.registerForm.value.client.length-1]._id).subscribe((e) => {
        let selectedClient = this.listNamesClients.find(x => x._id.toString()  ===this.registerForm.value.client[this.registerForm.value.client.length-1]._id.toString())
        selectedClient.site = e
      });
     }
    for (let cl of this.listNamesClients){
     let index = this.registerForm.value.client.findIndex(item => item._id === cl._id);
     if(index == -1){
     let secondIndex = this.listNamesClients.findIndex(item => item._id === cl._id);
     this.listNamesClients.splice(secondIndex,1)
     }
    }
  }
  setListSites(value, client) {
    let clientObject = this.listNamesClients.find((obj) => obj._id === client);
    let indiceClientObject = this.listNamesClients.findIndex(
      (obj) => obj._id === client
    );
    if (clientObject != null) {
      for (let site of value) {
        let siteObject: any;
        siteObject = clientObject.site.findIndex((obj) => obj._id === site);
        clientObject = { ...clientObject, siteSelected: value };
        this.clients_copy[indiceClientObject] = clientObject;
      }
    }
  }

  addClient(){
    this.modalService.open(PopupAddClientComponent);
    
    
    }
 
}
