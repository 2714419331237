<div class="modal-header">
  <h4 class="modal-title">Ajouter un nouvel type de prestation vitre</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="">
      <div class="form-row">
        <div class="form-group col">
          <label>Type </label>
          <input
            type="text"
            formControlName="type"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
          />
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">Champ obligatoire.</div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-4">
        <!-- Ajout de la classe pour centrer -->
        <div class="text-center">
          <!-- Ajout de la classe pour centrer -->
          <button
            (click)="onReset()"
            class="btn btn-secondary mr-4 button_list"
            type="reset"
          >
            Annuler
          </button>
          <button class="btn btn-primary button_list">Sauvegarder</button>
        </div>
      </div>
    </form>
  </div>
</div>
