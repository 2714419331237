import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import codes from 'src/app/BE_Postal_codes.json';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { ClientService } from 'src/app/_services/client.service';
import { UserService } from 'src/app/_services/user.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SiteService } from 'src/app/_services/site.service';
import * as  uuid from 'uuid';
import { ContactService } from 'src/app/_services/contact.service';
import { environment } from 'src/environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { WorkerService } from 'src/app/_services/worker.service';

@Component({
  selector: 'app-update-site',
  templateUrl: './update-site.component.html',
  styleUrls: ['./update-site.component.css','../../../../_shared/add_update.css']
})
export class UpdateSiteComponent implements OnInit {

  BaseUrl_site = environment.baseUrl.split('/api')[0] + '/media/sites/';

  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;

  
  codes_postales  : any= codes 
  contacts = []
  clients = []
  listesEtablishment = []
  statuts = ["ACTIF","ONE SHOT","OFFRE","STOP"]
  typesSites = ["Appartement","Bureau","Commerce","Communs bâtiment","Communs résidentiel ","Handyman","Maison","Parking"]
  city: { country_code: string; postal_code: string; place_name: string; admin_name1: string; admin_code1: string; admin_name2: string; admin_code2: string; admin_name3: string; admin_code3: string; latitude: number; longitude: number; accuracy: number; coordinates: { lon: number; lat: number; }; };
  city_codePostal: string;



  SiteDeclaration = false 
  closeResult: string;

  admins : any
  filesToSaved = []
  listFiles = []
  myUUID 


  //
  id 
  site
  idEtablissementInitial :any
  clientinitiaL :any
  contactsIntialToSite :any
  copyContactsIntialToSite :any
  adressRecuperate :any
  InitListeFiles = []
  workers :any
  //adress
 // contacts

 //editor text
 editorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: "15rem",
  width: "100%",
  minHeight: "5rem",
  placeholder: "",
  translate: "yes",
  defaultParagraphSeparator: "p",
  defaultFontName: "Arial",
  toolbarHiddenButtons: [
    ["insertImage", "insertVideo","insertHorizontalRule","removeFormat","toggleEditorMode"],

  ],
  customClasses: [
    {
      name: "quote",
      class: "quote",
    },
    {
      name: "redText",
      class: "redText",
    },
    {
      name: "titleText",
      class: "titleText",
      tag: "h1",
    },
  ],
  
  
};
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private etablissementService :EtablissementService,
    private clientService :ClientService,
    private userService :UserService,
    public iziToast: Ng2IzitoastService,
    private siteService :SiteService,
    private workerService :WorkerService,
    private route :ActivatedRoute,
    ) { }


  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    this.id = this.route.snapshot.params["id"];
    this.siteService.getSiteById(this.id).subscribe(data => {
       this.site = data;
       if(this.site.etablissement){
       this.idEtablissementInitial = this.site.etablissement._id
       }
       if(this.site && this.site.worker){
        for (let worker of this.site.worker){
         worker.firstName_lastName = worker["lastName"]+" "+ worker["firstName"]
        }
       }
       this.clientinitiaL  = this.site.client
       this.adressRecuperate = this.site.adress
       this.myUUID = this.site.key
    this.registerForm = this.formBuilder.group({
      etablissement : ['', ],
      name: ['', Validators.required ],
      numberSite: ['', Validators.required ],
      adress:this.formBuilder.array([]),
      type :['', Validators.required ],
      floor : ['', ],
      manager : ['',Validators.required ],
      client : ['', Validators.required],
      contact: [ ],
      startDate :['', ],
      worker :[],
      statut : ['', Validators.required],
      archive :  [false],
      document :['',],
      note:['', ],

      key :['',]
  },);
  for (let i of this.adressRecuperate){
    if(!i.city){
      i.city =" "
    }
    this.adress().push(this.formBuilder.group({
      street: i.street,
      number: i.number,
      postalCode: i.postalCode +" - "+i.city,
      city:i.city,
    }))}
  this.getEtablissements()
  this.getUsers()
  this.getClients()
  this.getContactsBySite()
  this.getlistDocumentsSites()
  this.getWorkers()
  })
}
  

getEtablissements(){
  this.etablissementService.getEtablissementsByAlphabetical().subscribe((e) => {
    this.listesEtablishment = e; 
    
  }) 
}
getUsers() {
  this.userService.getListAdmin().subscribe((e) => {
    this.admins = e;
  });
}

getContactsBySite(){
  this.siteService.listContactsBySite(this.id).subscribe(data => {
    this.contactsIntialToSite = data;
    this.copyContactsIntialToSite = this.contactsIntialToSite   
   
 })

}
getClients()
{
  if(!this.registerForm.value.etablissement || this.registerForm.value.etablissement == null)
  this.etablissementService.getClientsWithNotEtablissement().subscribe((e) => {
    this.clients = e;  
  }) 
  else{
    this.etablissementService.getClientsByEtablissement(this.registerForm.value.etablissement._id).subscribe((e) => {
      this.clients = e;  
    }) 
  }
}


getContacts(id){
  this.clientService.getContactsByClient(id).subscribe((e) => {
    this.contacts = e;
  });

}

getlistDocumentsSites(){
  this.siteService.listNamesFiles(this.id).subscribe(data => {
  this.listFiles = data;
  if( this.listFiles.length>0){
    for(let item of this.listFiles)
    this.InitListeFiles.push(item)
  }
  })
  }
  getWorkers() {
    this.workerService.getWorkerNotAffected().subscribe((e) => {
      this.workers = e;
    });
  }
adress(): FormArray {
  return this.registerForm.get("adress") as FormArray;
}

addQuantity() {
  this.adress().push(this.newadress());
}
removeQuantity(i: number) {
  this.adress().removeAt(i);
}
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;
  let filesList =[]

  if (this.registerForm.invalid) {
    return;
  }
  if (this.filesToSaved.length != 0) {
    for (let file of this.filesToSaved) {
        const originalFileName = file.name;
        let fileExtension = this.getFileExtension(originalFileName);
        fileExtension = '.' + fileExtension;
        let index = this.listFiles.length;
        const parts = originalFileName.split('.');
        const newFileName = this.registerForm.value.name + '_' + parts[0] + '_' + index + '.' + parts[1];
        const newFile: File = new File([file], encodeURIComponent(newFileName), { type: file.type });
        const formData: FormData = new FormData();
        formData.append('file', newFile);
        this.siteService.uploadFiles(formData, this.myUUID).subscribe(e => {
        })

        this.listFiles.push(newFileName)
    }
    let listConverteds = this.listFiles.filter(element => element.includes(this.registerForm.value.name));
    this.registerForm.value.document = listConverteds
  }
 
  if (!this.registerForm.value.adress[0].street || this.registerForm.value.adress[0].street == "") {
    this.iziToast.show({
      message: "Veuillez vérifier les données de la première adresse",
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
    });

    return false;
  }
  if (!this.registerForm.value.adress[0].postalCode || this.registerForm.value.adress[0].postalCode == "") {
    this.iziToast.show({
      message: "Veuillez vérifier les données de la première adresse",
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
    });

    return false;
  }
  if (!this.registerForm.value.adress[0].number || this.registerForm.value.adress[0].number == "") {
    this.iziToast.show({
      message: "Veuillez vérifier les données de la première adresse",
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
    });

    return false;
  }
  this.site.adress = []
  for (let i = 0; i < this.registerForm.value.adress.length; i++) {
    var objecadresse :any

    if((this.registerForm.value.adress[i] && (this.registerForm.value.adress[i].postalCode && !this.registerForm.value.adress[i].postalCode.place_name)) || this.registerForm.value.adress[i].postalCode ==""){
      objecadresse = {
        street: this.registerForm.value.adress[i].street,
         number: this.registerForm.value.adress[i].number, 
         postalCode: this.registerForm.value.adress[i].postalCode.split(" - ")[0], 
         city: this.registerForm.value.adress[i].city
      }

    }

    

    if(this.registerForm.value.adress[i] && this.registerForm.value.adress[i].postalCode && this.registerForm.value.adress[i].postalCode.place_name){
      objecadresse = {
        street: this.registerForm.value.adress[i].street,
         number: this.registerForm.value.adress[i].number, 
         postalCode: this.registerForm.value.adress[i].postalCode.postal_code, 
         city: this.registerForm.value.adress[i].postalCode.place_name
      }
    }

    this.site.adress.push(objecadresse)
    

  }
 
  this.registerForm.value.adress = this.site.adress
   // sauvegarde files
  //  if(this.filesToSaved.length != 0)
  //  {
  //    for(let file of this.filesToSaved)
  //    {
  //      const newFile: File = new File([file], file.name, {type: file.type});
  //      const formData: FormData = new FormData();
  //      formData.append('file', newFile);
  //      this.siteService.uploadFiles(formData,this.myUUID).subscribe(e =>{
  //      })
       
  //      filesList.push(file.name)
  //    }
  //    this.registerForm.value.document = filesList
     
  //  }
   if(this.registerForm.value.etablissement || this.registerForm.value.etablissement != null){
    this.registerForm.value.etablissement= this.registerForm.value.etablissement._id
    }
   this.registerForm.value.client =this.registerForm.value.client._id
   this.registerForm.value.manager = this.registerForm.value.manager._id
   if(this.registerForm.value.contact){
   let listFilesdContactsBySite = this.registerForm.value.contact.map(item => item._id);
     this.registerForm.value.contact = listFilesdContactsBySite
   }
     this.registerForm.value.key = this.myUUID
     let listConverteds = this.listFiles.filter(element => element.includes(this.registerForm.value.name));
     this.registerForm.value.document = listConverteds
     //this.registerForm.value.document = this.listFiles
     
this.siteService
      .EditSite(this.id,this.registerForm.value)
      .subscribe((data) => {
        this.iziToast.show({
          message: "Modification avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
    this.router.navigate(['/admin/chantiers' ])
      })

}

public dropped(files: NgxFileDropEntry[]) {
 
  this.files = files;
  const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
  const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];

  if (validExtensions.includes(fileExtension)) {
  this.listFiles.push(this.files[0].relativePath)
  }
  else
  {
      const targetRelativePath = this.files[0].relativePath;
      const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

      if (targetIndex !== -1) {
        files.splice(targetIndex, 1);
      } 
    this.iziToast.show({
      message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
    return;
  }
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        if(file.size < 5242880){
        this.filesToSaved.push(file)
        }
        else
        {
          const targetRelativePath = this.files[0].relativePath;
          const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
          if (targetIndex !== -1) {
            this.listFiles.splice(targetIndex, 1);
          } 
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay:true,
            overlayClose:true,
            
          });
          return;
        }
        if(this.filesToSaved.length > 10){
          this.listFiles.splice(this.filesToSaved.length-1 , 1);
          this.filesToSaved.splice(this.filesToSaved.length-1 , 1);
          this.iziToast.show({
            message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return;
        }
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}
deleteFileFromListFiles(file){
  const indexToRemove = this.listFiles.findIndex(obj => obj === file);
  if (indexToRemove !== -1) {
    this.listFiles.splice(indexToRemove, 1);
    this.filesToSaved.splice(indexToRemove, 1);
  }
   }

getCity(i) {
  let city;
  city = codes.find(
    (el) =>
      el.postal_code ===
      this.registerForm.value.adress[i].postalCode.postal_code
  );
}
newadress(): FormGroup {
  return this.formBuilder.group({
    street: "",
    number: "",
    postalCode: "",
    city: "",
  });
}
getNewIdEtablissement()
{
  if(!this.registerForm.value.etablissement)
  {
    this.registerForm.patchValue({
      client: '' // Update contactMain to an empty string
    });
    this.contactsIntialToSite = []

  }
  if(this.registerForm.value.etablissement && this.registerForm.value.etablissement._id != this.idEtablissementInitial){
    this.registerForm.patchValue({
      client: '' // Update contactMain to an empty string
    });
  }
  // else{
  //   this.site.client = this.clientinitiaL 
  //   this.contactsIntialToSite = this.copyContactsIntialToSite
  // }
}
getNewIdClient()
{
if(this.registerForm.value.etablissement && this.registerForm.value.etablissement._id != this.idEtablissementInitial)
this.contactsIntialToSite = []
else{
  if( this.registerForm.value.client._id != this.clientinitiaL._id ){
    this.contactsIntialToSite = []
  }
  // else{
  //   this.contactsIntialToSite = this.copyContactsIntialToSite
  // }
}  
}

openFileInNewWindow(key: string,filename) {
  let url :any
  url =this.  BaseUrl_site+key+"/"+filename
  window.open(url, '_blank');
}
getFileExtension(fileName: string): string {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

}
