import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { BenefitGlassService } from 'src/app/_services/benefit-glass.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SiteService } from 'src/app/_services/site.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { TypebenefitGlassService } from 'src/app/_services/typeBenefitGlass';
import { PopupAddTypebenefitGlassComponent } from '../../../popupAdd/popup-add-typebenefit-glass/popup-add-typebenefit-glass.component';

@Component({
  selector: 'app-add-glass',
  templateUrl: './add-glass.component.html',
  styleUrls: ['./add-glass.component.css','../../../../../_shared/add_update.css']
})
export class AddGlassComponent implements OnInit {

 
   sites :any
   listSubContractors :any
   typesBenefitGlass : any
  frequences =["Hebdomadaire","Mensuelle","Trimestrielle 1-4-7-10","Trimestrielle 2-5-8-11","Trimestrielle 3-6-9-12",
  "Quadrimestrielle","Semestrielle","Annuelle","Bi-annuelle"]
  contacts :any
  //typesMaterial =["Camions","Nacelles","Potences"]
  typesMaterial = [];
  listNumberOsiris =[]

  listMaterialsFromHtml = [] 
   

  registerForm: FormGroup;
  submitted = false;
  numerosOsiris : FormArray;

  listMonthsannual =["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]

  listMonthsannual_Bi_annual = []

  datesChooses = []
  monthChooses = []
  hebdates:any
  
  /*weekly*/
   dayToNumber = {
    Monday: "01",
    Tuesday: "02",
    Wednesday: "03",
    Thursday: "04",
    Friday: "05",
    saturday:"06",
    sunday:"07"
  };
  d: string;
  d1: string;
  dateAuj = new Date();
  frequenceSelected :any =""
  selectedColor = '#0e309f';
  billingFrequency =["12","41","46"]
  statuts = ["En cours","Terminé","One shot"]
  listDates =[]
  idSite
  site
  
  validWeeklyBenefitForm = true
  verifDiffrence3years = true
  workers: any;
  MonthSelectedforFrequenceAnual: number;

  selectedMonthsBiAnnual : string[] = [];
  MonthSelectedforFrequenceBi_annual :any
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private siteService :SiteService,
    private subContractorService :SubContractorService,
    private contactService :ContactService,
    private benefitGlassService :BenefitGlassService,
    private sharedService :SharedService,
    public iziToast: Ng2IzitoastService,
    private typeBenefitGlassSerice :TypebenefitGlassService,
    private modalService: NgbModal,


    ) { }


  ngOnInit() {
    this.idSite = this.sharedService.getIdSiteSendedTobenfitGlass();
    if(this.idSite){
      this.siteService.getSiteById(this.idSite).subscribe(data => {
        this.site = data;
        this.sharedService.setIdSiteSendTOBenefitGlass("");

     })
    }
       this.registerForm = this.formBuilder.group({
        site: ['', Validators.required],
        type: ['', Validators.required],
        subContractor : ['', Validators.required],
        contratSST :[false,],
        contact :['', Validators.required],
        description :['', Validators.maxLength(200)], 
        frequence : ['', Validators.required],
        startDate :['', Validators.required],
        typesMaterial : ['',],
        plaque :[''],
        controlCertificate :[false,],
        numerosOsiris:['', ],
        listMonths : ['', ],
        listDates : ['', ],
    },);
    this.registerForm.get('frequence').valueChanges.subscribe((option: string) => {
      this.updateFormControls(option);
    });
    this.registerForm.get('typesMaterial').valueChanges.subscribe((option: string) => {
      this.updateFormControlsPlaqueField(option);
    });
    this.d1 = this.dateAuj.toISOString().substr(0, 10);

    this.getSites()
    this.getSubContratcors()
    this.getContacts()
    this.getTypesBenefitsGlass()
    
}

getSites() {
  this.siteService.getSites().subscribe((e) => {
    this.sites = e;
  });
}

getSubContratcors()
{
  this.subContractorService.getList_subContractor().subscribe(
    (data) => {
        this.listSubContractors = data
        })
}

getContacts(){
  this.contactService.getContacts().subscribe(data => {
      this.contacts = data;  
        })              
        } 
        
getTypesBenefitsGlass(){
  this.typeBenefitGlassSerice.getTypesBenefitsGlass().subscribe(
    (data) => {
    this.typesBenefitGlass = data
            })
      }       

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

// frequnce controlle de saisie lors de saisie frequence dynamiquement de frequence à l'autre
private updateFormControls(option: string): void {
  if (option === 'Annuelle') {
    this.registerForm.addControl('listOfMonths', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonthsBiAnnual');
  
  }
  else if (option === 'Bi-annuelle') {
    this.registerForm.addControl('listOfMonthsBiAnnual', this.formBuilder.control('', Validators.required));
    this.registerForm.removeControl('listOfMonths');
  
  }
}

private updateFormControlsPlaqueField(option: string): void {
  if (option.includes('Camions')) {
    this.registerForm.addControl('plate', this.formBuilder.control('', Validators.required));  
  }
  else{
    this.registerForm.removeControl('plate');
  }
}

onSubmit() {
 
  this.submitted = true;
  if (this.registerForm.invalid) {     
      return;
  }
  let currentDate = new Date(this.registerForm.value.startDate);
  currentDate.setFullYear(currentDate.getFullYear() + 2);
  var endDate = currentDate.toISOString().slice(0, 10);
  this.registerForm.value.endDate = endDate 

  let parts = this.registerForm.value.startDate.split('-');
  let month = parseInt(parts[1]);
  let dayOfMotnth = parseInt(parts[2]); 

  if(this.registerForm.value.frequence == "Mensuelle"){
  let day = parseInt(parts[2]).toString();
  if(day.length == 1 ){
     day ="0"+day
  }
  this.datesChooses.shift();
  this.datesChooses.push(day)
  }

  let searchWord = "Trimestrielle"
  if ( (this.registerForm.value.frequence.indexOf(searchWord) !== -1)){   
    //month = month + 1
    // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
    //   month = month - 1
    // }
    // month decembre
    if(month == 13){
      month = 1
    }

    this.monthChooses.push(month);
    for (let i = 1; i < 4; i++) {
      month += 3;
      if (month > 12) {
        month = month - 12;
      }
      this.monthChooses.push(month);
      this.datesChooses =["01","01","01","01"] 
     }
     this.registerForm.value.listMonths = this.monthChooses 
    }


    if(this.registerForm.value.frequence == "Quadrimestrielle"){
      //month = month + 1
      // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
      //   month = month - 1
      // }
      //month decembre
      if(month == 13){
        month = 1
      }

      this.monthChooses.push(month);
      for (let i = 1; i < 3; i++) {
        month += 4;
        if (month > 12) {
          month = month - 12;
      }
        this.monthChooses.push(month);
        this.datesChooses =["01","01","01"] 
    }
    this.registerForm.value.listMonths = this.monthChooses 
  }


  if(this.registerForm.value.frequence == "Hebdomadaire"){
    if(this.hebdates == undefined || this.hebdates.length == 0){
      this.validWeeklyBenefitForm = false
      return;
    }
  }


  if(this.registerForm.value.frequence == "Hebdomadaire"){
    this.datesChooses = this.hebdates
  }

  if(this.registerForm.value.frequence == "Semestrielle"){
    //month = month + 1
    // if(dayOfMotnth == 1 && (new Date(this.registerForm.value.startDate) > new Date()) ){
    //   month = month - 1
    // }
    if(month == 13){
      month = 1
    }
    this.monthChooses.push(month);
    for (let i = 1; i < 2; i++) {
      month += 6;
      if (month > 12) {
        month = month - 12;
    }
      this.monthChooses.push(month);
      this.datesChooses =["01","01"] 
    }
    this.registerForm.value.listMonths = this.monthChooses 
    }

    if(this.registerForm.value.frequence == "Annuelle"){
      this.monthChooses.push(this.MonthSelectedforFrequenceAnual)
      this.registerForm.value.listMonths = this.monthChooses
      this.datesChooses =["01"]  
    }

    if(this.registerForm.value.frequence == "Bi-annuelle"){
      if(this.MonthSelectedforFrequenceBi_annual.length == 2)
      {
        this.registerForm.value.listMonths = this.MonthSelectedforFrequenceBi_annual
        this.datesChooses =["01","01"]  
      }
      else{
        this.iziToast.show({
          message:"Merci de choisir deux mois pour la fréquence Bi-annuelle.",
          messageColor:'#800f2f',
          titleColor:'#800f2f',
          progressBarColor:'#c9184a',
          icon:'bi-exclamation-diamond',	
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          //overlay:true,
          overlayClose:true,	
         });
        return false
      }
    }

    if(this.site){
      this.registerForm.value.site = this.site._id
      }

  this.registerForm.value.listDates = this.datesChooses 
  if(this.registerForm.value.plate){
    this.registerForm.value.plaque = this.registerForm.value.plate
  }
  let convertedlistMonths =[]
  for(let month of this.registerForm.value.listMonths){
    if(month < 10){
      month = "0"+month
      convertedlistMonths.push(month)
    }
    else{
      convertedlistMonths.push(month.toString())
    }
  }
  this.registerForm.value.listMonths = convertedlistMonths
  this.benefitGlassService.createBenefitGlass(this.registerForm.value).subscribe(
    (data) => {
      this.router.navigate(['/admin/chantiers/details/'+this.registerForm.value.site ])
          this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        }
        )
}
newchoix(): FormGroup {
  return this.formBuilder.group({
    numero: "",
    
  })
}

onReset() {

}

recuperateListMaterials(material){
  
  this.listMaterialsFromHtml = material 
}


 
initTypeMaterail()
{
  if(this.listMaterialsFromHtml.length  == 0)
  this.typesMaterial = [
    {  name: 'Camions', disabled: false },
    {  name: 'Nacelles', disabled: false },
    {  name: 'Potences', disabled: false },
   
  ];
  else {
    for (let type of this.listMaterialsFromHtml){
      if(type.name == "Nacelles"){
        this.typesMaterial = [
          {  name: 'Camions', disabled: false },
          {  name: 'Nacelles', disabled: false },
          {  name: 'Potences', disabled: true },
         
        ];
        break;
      }
      else if(type.name == "Potences"){
        this.typesMaterial = [
          {  name: 'Camions', disabled: false },
          {  name: 'Nacelles', disabled: true },
          {  name: 'Potences', disabled: false },
         
        ];
        break;
      }
      else{
        this.typesMaterial = [
          {  name: 'Camions', disabled: false },
          {  name: 'Nacelles', disabled: false },
          {  name: 'Potences', disabled: false },
         
        ];
      }

    }
  }
  


}

// calcul benefitGlass
getFreguence(frequence ) {
  this.frequenceSelected = frequence;
      }

      //Hebdomadaire 
chooseDate(value){
  let index = this.listDates.indexOf(value)
  if(index == -1){
  this.listDates.push(value)
  }
  else{
  this.listDates.splice(index,1)
  }
  const numbersList = this.listDates.map(day => this.dayToNumber[day]);
  this.hebdates=numbersList
  if(this.hebdates.length > 0){
    this.validWeeklyBenefitForm = true
  }
}

getSelectedMonth(value)
{
 // this.selectedMonth =value
 
  const monthIndex = this.listMonthsannual.indexOf(value)+1;
   this.MonthSelectedforFrequenceAnual = monthIndex
  //this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

getSelectedMonthBiAnnual(event: any) {
  this.selectedMonthsBiAnnual = event;
  let monthIndex1
  let monthIndex2
  let listMonths =[]
  if(this.selectedMonthsBiAnnual[0]){
  monthIndex1  = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[0]['name']);
  listMonths.push(monthIndex1+1)
  }
  if(this.selectedMonthsBiAnnual[1]){
   monthIndex2 = this.listMonthsannual.indexOf(this.selectedMonthsBiAnnual[1]['name']);
   listMonths.push(monthIndex2+1)
  }
   this.MonthSelectedforFrequenceBi_annual = listMonths
}

gemothsBiAnnual() {
  this.listMonthsannual_Bi_annual = [
    { name: 'Janvier', disabled: false },
    { name: 'Février', disabled: false },
    { name: 'Mars', disabled: false },
    { name: 'Avril', disabled: false },
    { name: 'Mai', disabled: false },
    { name: 'Juin', disabled: false },
    { name: 'Juillet', disabled: false },
    { name: 'Août', disabled: false },
    { name: 'Septembre', disabled: false },
    { name: 'Octobre', disabled: false },
    { name: 'Novembre', disabled: false },
    { name: 'Décembre', disabled: false },
  ];

  if (this.selectedMonthsBiAnnual.length >= 2) {
    const selectedMonthNames = this.selectedMonthsBiAnnual.map((month) => month['name']);

    this.listMonthsannual_Bi_annual = this.listMonthsannual_Bi_annual.map((month) => {
      if (!selectedMonthNames.includes(month.name)) {
        return { ...month, disabled: true };
      }
      return month;
    });
  }
}

shouldShowAttestationControl(): boolean {
  return this.listMaterialsFromHtml.some(item => item.name === 'Nacelles' || item.name === 'Potences');
}

shouldShowPlaque(): boolean {
  return this.listMaterialsFromHtml.some(item => item.name === 'Camions');
}

cancel(){
  if(this.site){
    this.router.navigate(["admin/chantiers/details", this.site._id]);
  }
  else{
    this.router.navigate(['/admin/chantiers/vitres' ])
  }
}
addType(){
  let modalRef
  modalRef =this.modalService.open(PopupAddTypebenefitGlassComponent);
}
}
