import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import codes from 'src/app/BE_Postal_codes.json';
import * as  uuid from 'uuid';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { WorkerService } from 'src/app/_services/worker.service';
import codesContries from 'src/app/codes_countries.json';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-update-sub-contractor',
  templateUrl: './update-sub-contractor.component.html',
  styleUrls: ['./update-sub-contractor.component.css','../../../../_shared/add_update.css']
})
export class UpdateSubContractorComponent implements OnInit {

  changeCity = false
  listContactsByThisSubContractor :any

  separateDialCode = true ;
  SearchCountryField = SearchCountryField;
  TooltipLabel ;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];


  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;
  
  filesToSaved = []
  listFiles = []
  InitListeFiles =[]

  codes_postales :any = codes 
  contacts :any
  workers :any
  city: any;
  city_codePostal: any;
  myUUID 


  id
  sub_contrator
  contactPrincipal_sub_contrator :any
  contryIsoSelected:any =CountryISO.Belgium


  contactsbysubContractor :any
  workersbysubContractor :any
  BaseUrl_subContractor = environment.baseUrl.split('/api')[0] + '/media/sub_contrator/';
  codePostalCityName :String = ""

  selectedFile: File | null = null;
  selectedFileUrl: string | null = null;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private subContractorService :SubContractorService,
    private contactService :ContactService,
    public iziToast: Ng2IzitoastService,
    private workerService :WorkerService,
    private route :ActivatedRoute

    ) { }


  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    this.id = this.route.snapshot.params["id"];
                  this.subContractorService.getSubContratcorById(this.id).subscribe(data => {
                      this.sub_contrator = data;
                      this.myUUID = this.sub_contrator.key
                     
                      this.contactPrincipal_sub_contrator = this.sub_contrator.contactMain
                      if(this.sub_contrator.postalCode){
                      this.codePostalCityName = this.sub_contrator.postalCode + ' - ' + this.sub_contrator.city
                      }
                      if(this.sub_contrator && this.sub_contrator.contactMain){
                     this.contactPrincipal_sub_contrator.firstName_lastName = this.sub_contrator.contactMain["lastName"]+" "+this.sub_contrator.contactMain["firstName"]
                      }
                      if(this.sub_contrator && this.sub_contrator.phone){
                        let prefix = this.sub_contrator.phone.split(' ')
                        if (prefix && prefix.length>0){
                          let myCountry=codesContries.find(x=>x.dial_code === prefix[0])
                          if(myCountry){
                            this.contryIsoSelected = CountryISO[myCountry.name]
                          }
                          
                        }
                      }
                  
    this.registerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      tva: ['', ],
      address : ['', ],
      number : ['', ],
      postalCode : ['', ],
      city : ['', ],
      contact: ['',],
      contactMain :['',],
      email:['', Validators.email],
      phone :['', ],
      worker :['', ],
      tauxTime : ['',],
      document :['',],
      key :['',this.CountryISO]
      

      // acceptTerms: [false, Validators.requiredTrue]
  },);
  this.getContactsNotAffected()
  this.getWorkersNotAffected()
  this.getContactsbysubContractor()
  this.getWorkersbysubContractor()
  this.getlistDocumentsSub_contractor()
})
}

getContactsNotAffected() {

  this.contactService.getContacts().subscribe((e) => {
    this.contacts = e;
  });

}

getWorkersNotAffected() {
  this.workerService.getWorkerNotAffected().subscribe((e) => {
    this.workers = e;
  });

}

getContactsbysubContractor(){
  this.subContractorService.getContactsBySub_contractor(this.id).subscribe(data => {
    this.contactsbysubContractor = data;
    this.listContactsByThisSubContractor = this.contactsbysubContractor;
    
    if(!this.contacts) {
      this.contacts = []; // Initialize this.contacts as an empty array if it's not already defined
    }
    
    if(this.contacts.length == 0){
      this.contacts = this.contactsbysubContractor;
    } else {
      this.contacts =  [...this.contacts, ...this.contactsbysubContractor.filter(item2 => !this.contacts.some(item1 => item1._id === item2._id))];
    }
  });
}
getWorkersbysubContractor(){
this.subContractorService.getWorkersBySub_contractor(this.id).subscribe(data => {
  this.workersbysubContractor = data;
  if(this.workers && this.workers.length == 0){
    this.workers = this.workersbysubContractor
  }
  else{
  this.workers =  [...this.workers, ...this.workersbysubContractor.filter(item2 => !this.workers.some(item1 => item1._id === item2._id))];
  }
})
}

getlistDocumentsSub_contractor(){
this.subContractorService.listNamesFiles(this.id).subscribe(data => {
this.listFiles = data;
if( this.listFiles.length>0){
  for(let item of this.listFiles)
  this.InitListeFiles.push(item)
}
})
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
    this.submitted = true;
    let filesList =[]
    //stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    if (this.filesToSaved.length != 0) {
      for (let file of this.filesToSaved) {
          const originalFileName = file.name;
          let fileExtension = this.getFileExtension(originalFileName);
          fileExtension = '.' + fileExtension;
          let index = this.listFiles.length;
          const parts = originalFileName.split('.');
          const newFileName = this.registerForm.value.companyName + '_' + parts[0] + '_' + index + '.' + parts[1];
          const newFile: File = new File([file], encodeURIComponent(newFileName), { type: file.type });
          const formData: FormData = new FormData();
          formData.append('file', newFile);
          this.subContractorService.uploadFiles(formData, this.myUUID).subscribe(e => {
          })
  
          this.listFiles.push(newFileName)
      }
      let listConverteds = this.listFiles.filter(element => element.includes(this.registerForm.value.companyName));
      this.registerForm.value.document = listConverteds
    }
    if(this.registerForm.value.phone){
      this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
    }
    if( this.registerForm.value.postalCode){
    this.registerForm.value.postalCode=this.registerForm.value.postalCode["postal_code"]
    }
    if(this.registerForm.value.contactMain || this.registerForm.value.contactMain != null){
      this.registerForm.value.contactMain= this.registerForm.value.contactMain._id
      }
      this.registerForm.value.key = this.myUUID
      const exists = this.registerForm.value.contact.some(obj => obj._id === this.registerForm.value.contactMain);
            if(this.registerForm.value.contactMain && !exists ){
            let msg = "Le contact principal choisi n'existe pas dans la liste des contacts de ce client."
            this.iziToast.show({
              message: msg,
              messageColor:'#800f2f',
              titleColor:'#800f2f',
              progressBarColor:'#c9184a',
              icon:'bi-exclamation-diamond',
              imageWidth:45,
              position:'topRight',
              timeout:5000,
              backgroundColor:'#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay:true,
              overlayClose:true,
              
            });
            this.registerForm.patchValue({
              contactMain: '' // Update contactMain to an empty string
            });
            return;
          }
          let listConverteds = this.listFiles.filter(element => element.includes(this.registerForm.value.companyName));
     this.registerForm.value.document = listConverteds
    this.subContractorService.EditSubContractor(this.id,this.registerForm.value).subscribe(
      (data) => {
        this.iziToast.show({
          message:'Modification avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          });
        this.router.navigate(['/admin/prestataires' ])
      })



    

}

public dropped(files: NgxFileDropEntry[]) {
  this.files = files;
    const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];

if (validExtensions.includes(fileExtension)) {
this.listFiles.push(this.files[0].relativePath)
}
else
{
  const targetRelativePath = this.files[0].relativePath;
  const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

  if (targetIndex !== -1) {
    files.splice(targetIndex, 1);
  } else 
this.iziToast.show({
  message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
  messageColor:'#800f2f',
  titleColor:'#800f2f',
  progressBarColor:'#c9184a',
  icon:'bi-exclamation-diamond',
  imageWidth:45,
  position:'topRight',
  timeout:5000,
  backgroundColor:'#ff8fa3',
  transitionIn: 'flipInX',
  transitionOut: 'flipOutX',
  overlay:true,
  overlayClose:true,
  
});
return;
}
  //this.listFiles.push(this.files[0].relativePath)
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        if(file.size < 5242880){
        this.filesToSaved.push(file)
        }
        else{
          let index = this.listFiles.findIndex(filename => filename === file.name);
          if (index !== -1) {
            this.listFiles.splice(index, 1);
          }
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay:true,
            overlayClose:true,
            
          });
          return;
        }
        if((this.listFiles.length) > 10){
          this.listFiles.splice(this.listFiles.length-1 , 1);
          this.filesToSaved.splice(this.listFiles.length-1 , 1);
          this.iziToast.show({
            message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return;
        }

      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
 
}

public fileOver(event){
}

public fileLeave(event){
}


getCity()
{
  this.changeCity = true

  if(this.registerForm.value.postalCode != null)
  {
  this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
// => {name: "Albania", code: "AL"}
      this.registerForm.value.city = this.city
      this.city_codePostal = this.city["place_name"];
      this.registerForm.value.postalCode = this.city["postal_code"]
  }
  else{
    this.city_codePostal = ""
  }

}

deleteFileFromListFiles(file){
const indexToRemove = this.listFiles.findIndex(item => item === file);
if (indexToRemove !== -1) {
  this.listFiles.splice(indexToRemove, 1);
  this.filesToSaved.splice(indexToRemove, 1);
}
}



getContactByThisSubContractors(){
  this.listContactsByThisSubContractor = this.registerForm.value.contact
}

openFileInNewWindow(key: string,filename) {
  let url :any

  url =this.BaseUrl_subContractor+key+"/"+filename
  
 
  window.open(url, '_blank');
}
getFileExtension(fileName: string): string {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

}




