import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import codes from 'src/app/BE_Postal_codes.json';
import { PopupAddClientComponent } from '../../popupAdd/popup-add-client/popup-add-client.component';
import { PopupAddContactComponent } from '../../popupAdd/popup-add-contact/popup-add-contact.component';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { ClientService } from 'src/app/_services/client.service';
import { UserService } from 'src/app/_services/user.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SiteService } from 'src/app/_services/site.service';
import * as  uuid from 'uuid';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { WorkerService } from 'src/app/_services/worker.service';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.css','../../../../_shared/add_update.css']
})
export class AddSiteComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;

  
  codes_postales  : any = codes 
  contacts = []
  clients = []
  listesEtablishment = []
  statuts = ["ACTIF","ONE SHOT","OFFRE","STOP"]
  typesSites = ["Appartement","Bureau","Commerce","Communs bâtiment","Communs résidentiel ","Handyman","Maison","Parking"]
  city: { country_code: string; postal_code: string; place_name: string; admin_name1: string; admin_code1: string; admin_name2: string; admin_code2: string; admin_name3: string; admin_code3: string; latitude: number; longitude: number; accuracy: number; coordinates: { lon: number; lat: number; }; };
  city_codePostal: string;



  SiteDeclaration = false 
  closeResult: string;

  admins : any
  filesToSaved = []
  listFiles = []
  myUUID = uuid.v4();
  numberLastSite: any;
  workers :any

  //editor text 
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      ["insertImage", "insertVideo","insertHorizontalRule","removeFormat","toggleEditorMode"],

    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    
    
  };

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private etablissementService :EtablissementService,
    private clientService :ClientService,
    private userService :UserService,
    public iziToast: Ng2IzitoastService,
    private siteService :SiteService,
  private workerService :WorkerService) { }


  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    
    // Afficher le tableau mis à jour
    this.registerForm = this.formBuilder.group({
      etablissement : [ ],
      name: ['', ],
      numberSite: ['', Validators.required ],
      adress:this.formBuilder.array([]),
      type : ['', Validators.required ],
      floor : ['', ],
      manager : ['',Validators.required ],
      client : ['', Validators.required],
      contact: [ ],
      startDate :['', ],
      worker :[],
      statut : ['', Validators.required],
      archive :  [false],
      document :['',],
      note:['', ],
      key :['',]
  },);
  this.registerForm.get('client').valueChanges.subscribe(() => {
    // Réinitialiser le champ "Contact(s)" lorsque le "Client" change
    this.registerForm.get('contact').setValue(null);
  });
  this.adress().push(
    this.formBuilder.group({
      street: "",
      number: "",
      postalCode: "",
      city: "",
    })
  );
  this.getEtablissements()
  this.getUsers()
  this.getClients()
  this.getNumberLastSite()
  this.getWorkers()

}

getEtablissements(){
  this.etablissementService.getEtablissementsByAlphabetical().subscribe((e) => {
    this.listesEtablishment = e; 
    
  }) 
}
getUsers() {
  this.userService.getListAdmin().subscribe((e) => {
    this.admins = e;
  });
}
getClients()
{
  if(!this.registerForm.value.etablissement)
  this.etablissementService.getClientsWithNotEtablissement().subscribe((e) => {
    this.clients = e;     
  }) 
  else{
    this.etablissementService.getClientsByEtablissement(this.registerForm.value.etablissement).subscribe((e) => {
      this.clients = e;     
    }) 
  }
}
getNumberLastSite(){
  this.siteService.getNumberLastSiteAdded().subscribe((e:any) => {
    this.numberLastSite = e+1;    
    this.registerForm.value.numberSite =  this.numberLastSite
    this.registerForm.patchValue({ numberSite: this.numberLastSite }); // Utilisez patchValue pour mettre à jour le formulaire

  }) 
}
getWorkers() {
  this.workerService.getWorkerNotAffected().subscribe((e) => {
    this.workers = e;
  });
}
getContacts(id){
  this.clientService.getContactsByClient(id).subscribe((e) => {
    this.contacts = e;
  });

}
adress(): FormArray {
  return this.registerForm.get("adress") as FormArray;
}

addQuantity() {
  this.adress().push(this.newadress());
}
removeQuantity(i: number) {
  this.adress().removeAt(i);
}
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;
  let filesList =[]

  if (this.registerForm.invalid) {
    return;
  }
 
  if (!this.registerForm.value.adress[0].street) {
    this.iziToast.show({
      message: "Veuillez vérifier les données de la première adresse",
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
    });

    return false;
  }
  if (!this.registerForm.value.adress[0].postalCode) {
    this.iziToast.show({
      message: "Veuillez vérifier les données de la première adresse",
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
    });

    return false;
  }
  if (!this.registerForm.value.adress[0].number) {
    this.iziToast.show({
      message: "Veuillez vérifier les données de la première adresse",
      messageColor: "#800f2f",
      titleColor: "#800f2f",
      progressBarColor: "#c9184a",
      icon: "bi-exclamation-diamond",
      imageWidth: 45,
      position: "topRight",
      timeout: 5000,
      backgroundColor: "#ff8fa3",
    });

    return false;
  }
  for (let i = 0; i < this.registerForm.value.adress.length; i++) {
    let city = "";
    city = this.registerForm.value.adress[i].postalCode.place_name;
    this.registerForm.value.adress[i].postalCode =
      this.registerForm.value.adress[i].postalCode["postal_code"];
    this.registerForm.value.adress[i].city = city;
    if(!this.registerForm.value.adress[i].city && this.registerForm.value.adress[i].city == "" || this.registerForm.value.adress[i].city == null){
    this.registerForm.value.adress[i].city = ""
    }
    if(!this.registerForm.value.adress[i].postalCode && this.registerForm.value.adress[i].postalCode == "" || this.registerForm.value.adress[i].postalCode == null){
    this.registerForm.value.adress[i].postalCode = ""
    }
  }
   // sauvegarde files
   if(this.filesToSaved.length != 0)
   {
     for(let file of this.filesToSaved)
     {
        const originalFileName = file.name;
        let fileExtension = this.getFileExtension(originalFileName);
        fileExtension ='.'+fileExtension
         const timestamp = Date.now().toString().slice(-2); // Get the last two digits of the timestamp
         let index =this.filesToSaved.indexOf(file) 
         index =index+1
         var parts = originalFileName.split('.');
        const newFileName = this.registerForm.value.name+'_'+parts[0]+'_'+index+'.'+parts[1]
        const newFile: File = new File([file], encodeURIComponent(newFileName), {type: file.type});
        const formData: FormData = new FormData();
        formData.append('file', newFile);
       this.siteService.uploadFiles(formData,this.myUUID).subscribe(e =>{
       })
       
       filesList.push(newFileName)
     }
     this.registerForm.value.document = filesList
     
   }
  
   // sauvegarde chnatier
   this.registerForm.value.key = this.myUUID
     //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
this.siteService
      .createSite(this.registerForm.value)
      .subscribe((data) => {
        this.iziToast.show({
          message: "Ajout avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
    this.router.navigate(['/admin/chantiers' ])
      })

}


public dropped(files: NgxFileDropEntry[]) {
 
  this.files = files;
  const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
  const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];

  if (validExtensions.includes(fileExtension)) {
  this.listFiles.push(this.files[0].relativePath)
  }
  else
  {
      const targetRelativePath = this.files[0].relativePath;
      const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

      if (targetIndex !== -1) {
        files.splice(targetIndex, 1);
      } 
    this.iziToast.show({
      message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
    return;
  }
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        if(file.size < 5242880){
        this.filesToSaved.push(file)
        }
        else
        {
          const targetRelativePath = this.files[0].relativePath;
          const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
          if (targetIndex !== -1) {
            this.listFiles.splice(targetIndex, 1);
          } 
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay:true,
            overlayClose:true,
            
          });
          return;
        }
        //if( this.filesToSaved)
        if(this.filesToSaved.length > 10){
          this.listFiles.splice(this.filesToSaved.length-1 , 1);
          this.filesToSaved.splice(this.filesToSaved.length-1 , 1);
          this.iziToast.show({
            message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return;
        }
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}
//remove file
deleteFileFromListFiles(file){
  const indexToRemove = this.listFiles.findIndex(obj => obj === file);
  if (indexToRemove !== -1) {
    this.listFiles.splice(indexToRemove, 1);
    this.filesToSaved.splice(indexToRemove, 1);
  }
   }

getCity(i) {
  let city;
  city = codes.find(
    (el) =>
      el.postal_code ===
      this.registerForm.value.adress[i].postalCode.postal_code
  );
}



addClient(){
  let modalRef
  modalRef =this.modalService.open(PopupAddClientComponent);
  if(this.registerForm.value.etablissement)
  modalRef.componentInstance.etab = this.registerForm.value.etablissement;
//this.modalService.open(PopupAddClientComponent);
}
addContact(){
  let modalRef
  modalRef =this.modalService.open(PopupAddContactComponent);
  modalRef.componentInstance.client = this.registerForm.value.client;
  }

open(content) {
  this.modalService
    .open(content, { ariaLabelledBy: "modal-basic-title" })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return "by pressing ESC";
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return "by clicking on a backdrop";
  } else {
    return `with: ${reason}`;
  }
}

newadress(): FormGroup {
  return this.formBuilder.group({
    street: "",
    number: "",
    postalCode: "",
    city: "",
  });
}

getEtablissement()
{
  this.registerForm.patchValue({
    client: '' ,// Update contactMain to an empty string
    contact :[]
  });
  
}
getFileExtension(fileName: string): string {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}
}
