import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SharedService } from 'src/app/_services/shared.service';
import { TypebenefitGlassService } from 'src/app/_services/typeBenefitGlass';

@Component({
  selector: 'app-popup-update-typebenefit-glass',
  templateUrl: './popup-update-typebenefit-glass.component.html',
  styleUrls: ['./popup-update-typebenefit-glass.component.css']
})
export class PopupUpdateTypebenefitGlassComponent implements OnInit {
  idType
  city :any 
  registerForm: FormGroup;
  submitted = false;
  typeBenefit: any;
  constructor(private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private typeBenefitGlassService :TypebenefitGlassService,
    public iziToast: Ng2IzitoastService,
    private sharedService :SharedService

    ) { }



    ngOnInit() {
       this.typeBenefitGlassService.getTypeBenefitGlassById(this.idType).subscribe(data => {
        this.typeBenefit = data;
      })
          this.registerForm = this.formBuilder.group({
            type : ['', Validators.required ],
              
          },);

       
      }
      
      // convenience getter for easy access to form fields
      get f() { return this.registerForm.controls; }
      
      onSubmit() {
        this.submitted = true;
      
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
      
        this.typeBenefitGlassService.EditTypeBenefiGlasst(this.idType,this.registerForm.value).subscribe(
          (data) => {
             this.iziToast.show({
          message:'Modification avec succés',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
           this.sharedService.emitSubmitEvent()
            this.modal.dismiss();
              })
      }
      
      onReset() {
        this.modal.dismiss();
      
      }

}
