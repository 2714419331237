import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ClientService } from 'src/app/_services/client.service';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { UserService } from 'src/app/_services/user.service';
import codes from "src/app/BE_Postal_codes.json";
import { PopupAddClientComponent } from '../../popupAdd/popup-add-client/popup-add-client.component';



@Component({
  selector: 'app-update-etablissement',
  templateUrl: './update-etablissement.component.html',
  styleUrls: ['./update-etablissement.component.css', "../../../../_shared/add_update.css",]
})
export class UpdateEtablissementComponent implements OnInit {

  admins : any
  clients: any;
  clients_copy: any = [];
  clientsrecperate :any =[]
  registerForm: FormGroup;
  submitted = false;
  listNamesClients: any = [];
  codes_postales :any = codes;
  city: any;
  city_codePostal: string;
  list_managers = ["Solenn", "Karen", "Valérie"];
  sitesEtablissement =[]
  id
etablissment
name
//adress
manager
sites
adressRecuperate = []
clientsEtablissement
test1
test2
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private etbalissementService: EtablissementService,
    private clientService: ClientService,
    private userService :UserService ,
    public iziToast: Ng2IzitoastService,
    private route: ActivatedRoute,
    private modalService: NgbModal,

  ) {}

  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    this.getClients();
    this.getUsers();
    this.id = this.route.snapshot.params["id"];
    this.etbalissementService.getEtablissementById(this.id).subscribe(data => {
      this.etablissment = data;
      this.name = this.etablissment.name
      this.manager = this.etablissment.manager
      this.clientsEtablissement = this.etablissment.client
      this.sites =  this.etablissment.site
      this.adressRecuperate = this.etablissment.adress
      this.getsite(0);
   
    
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      adress: this.formBuilder.array([]),
      manager: ["", Validators.required],
      client: [""],
      site: [""],
    });
    for (let i of this.adressRecuperate){
      if(!i.city){
        i.city =" "
      }
      this.adress().push(this.formBuilder.group({
        street: i.street,
        number: i.number,
        postalCode: i.postalCode +" - "+i.city,
        city:i.city,
      }))}
  })
  }
  getClients() {
    this.clientService.getClients().subscribe((e) => {
      this.clients = e;
    });
  }

  getUsers() {
    this.userService.getListAdmin().subscribe((e) => {
      this.admins = e;
    });
  }

  adress(): FormArray {
    return this.registerForm.get("adress") as FormArray;
  }

  addQuantity() {
    this.adress().push(this.newadress());
  }
  removeQuantity(i: number) {
    this.adress().removeAt(i);
  }
  get f() {
    return this.registerForm.controls;
  }
  getsite(counter){
    if(counter == this.clientsEtablissement.length){
      this.suivreGetting(0)
      return false;
    }
    if(this.clientsEtablissement[counter]._id && this.clientsEtablissement[counter]._id != undefined){
      this.clientService.getsiteByClient(this.clientsEtablissement[counter]._id).subscribe((e) => {
        this.clientsEtablissement[counter].site = e 
        counter++
        this.getsite(counter)
        return false;
        });
    }
      
      //this.updatelistesitesClientes()
  }
  //
  
   suivreGetting(counter){
    if(counter === this.clientsEtablissement.length  ){
      return false;
    }
    this.clientsEtablissement[counter].selectedSite = []
    
    if(this.clientsEtablissement[counter].site && this.clientsEtablissement[counter].site.length > 0){
      for( let j=0;j<this.sites.length;j++){
        let objjj : any
      objjj  = this.clientsEtablissement[counter].site.find(x=> x._id.toString() === this.sites[j]._id.toString());
        if(objjj && objjj != undefined){
          let index = this.clientsEtablissement[counter].selectedSite.find(x => x._id.toString() === objjj._id.toString())
          if(!index){
            this.clientsEtablissement[counter].selectedSite.push(objjj)

          }
        }
        if(j === this.sites.length-1){
          this.clientsEtablissement[counter].site = this.clientsEtablissement[counter].site.filter( x => !this.clientsEtablissement[counter].selectedSite.filter( y => y._id.toString() === x._id.toString()).length);
          counter++
          this.suivreGetting(counter)
          return false;
        }
        
      }
  }
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
       this.etablissment.site =[]
    if(this.etablissment.client && this.etablissment.client.length >0){
      for(var i=0;i<this.etablissment.client.length;i++){
        if(this.etablissment.client[i].selectedSite && this.etablissment.client[i].selectedSite.length >0){
          this.etablissment.site.push(...this.etablissment.client[i].selectedSite)
          this.etablissment.site = this.etablissment.site.filter(
            (obj, index) =>
            this.etablissment.site.findIndex((item) => item._id.toString() === obj._id.toString()) === index
          );
        }
      }
    }
    this.etablissment.adress = []
    for (let i = 0; i < this.registerForm.value.adress.length; i++) {
      var objecadresse :any

      if((this.registerForm.value.adress[i] && (this.registerForm.value.adress[i].postalCode && !this.registerForm.value.adress[i].postalCode.place_name)) || this.registerForm.value.adress[i].postalCode ==""){
        objecadresse = {
          street: this.registerForm.value.adress[i].street,
           number: this.registerForm.value.adress[i].number, 
           postalCode: this.registerForm.value.adress[i].postalCode.split(" - ")[0], 
           city: this.registerForm.value.adress[i].city
        }

      }

      

      if(this.registerForm.value.adress[i] && this.registerForm.value.adress[i].postalCode && this.registerForm.value.adress[i].postalCode.place_name){
        objecadresse = {
          street: this.registerForm.value.adress[i].street,
           number: this.registerForm.value.adress[i].number, 
           postalCode: this.registerForm.value.adress[i].postalCode.postal_code, 
           city: this.registerForm.value.adress[i].postalCode.place_name
        }
      }

      this.etablissment.adress.push(objecadresse)
      

    }
    this.etablissment.manager =this.registerForm.value.manager._id
    this.etbalissementService
      .EditEtablissement(this.id,this.etablissment)
      .subscribe((data) => {
        this.iziToast.show({
          message: "Modification avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.router.navigate(["/admin/etablissements"]);
      });
  }

  getCity(i) {
    let city;
    city = codes.find(
      (el) =>
        el.postal_code ===
        this.registerForm.value.adress[i].postalCode.postal_code
    );
  }

  newadress(): FormGroup {
    return this.formBuilder.group({
      street: "",
      number: "",
      postalCode: "",
      city: "",
    });
  }
  deleteSite(client,site){
    client.site.push(site)
    client.selectedSite.splice(client.selectedSite.indexOf(site),1)
    client.site = client.site.filter( x => !client.selectedSite.filter( y => y._id.toString() === x._id.toString()).length);
  }


  
  setListNamesClients() {
    let newobject = this.registerForm.value.client[this.registerForm.value.client.length -1]
    this.etablissment.client.push(newobject)
    if(this.registerForm.value.client[this.registerForm.value.client.length-1] && this.registerForm.value.client[this.registerForm.value.client.length-1] != undefined){
    this.clientService.getsiteByClient(this.registerForm.value.client[this.registerForm.value.client.length -1]._id).subscribe((e) => {
      newobject.site = e
    });
  }
  }
  onRemoveClient($event){
    if(this.etablissment.client && this.etablissment.client.length >0){
      let found = this.etablissment.client.find(x => x._id.toString() === $event.value._id.toString())
      this.etablissment.client.splice(this.etablissment.client.indexOf(found),1)
    }
  }
  setListSites(value, client) {
      if(client.selectedSite && client.selectedSite.length >0){
        let foundInSlected = client.selectedSite.find(x => x._id.toString() === value._id.toString())
      if(!foundInSlected){
        client.selectedSite.push(value)
      }  
    }
    if(!client.selectedSite || (client.selectedSite && client.selectedSite.length <1)){
      client.selectedSite = []
      client.selectedSite.push(value)
    }
  }
  removeSite($event,client){
    if(client.selectedSite && client.selectedSite.length >0){
    let found = client.selectedSite.find(x => x._id.toString() === $event.value._id.toString())
    client.selectedSite.splice(client.selectedSite.indexOf(found),1)
    }
  }

  addClient(){
    this.modalService.open(PopupAddClientComponent);
    }
}
