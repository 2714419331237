import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import codes from 'src/app/BE_Postal_codes.json';
import * as  uuid from 'uuid';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { WorkerService } from 'src/app/_services/worker.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupAddContactComponent } from '../../popupAdd/popup-add-contact/popup-add-contact.component';

@Component({
  selector: 'app-add-sub-contractor-component',
  templateUrl: './add-sub-contractor-component.component.html',
  styleUrls: ['./add-sub-contractor-component.component.css','../../../../_shared/add_update.css']
})
export class AddSubContractorComponentComponent implements OnInit {

  listContactsByThisSubContractor :any


  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];


  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;
  
  filesToSaved = []
  listFiles = []

  codes_postales  : any = codes 
  contacts :any
  // workers = [
  //   {  name: 'Ouvrier 1' },
  //   {  name: 'Ouvrier 2' },
  //   {  name: 'Ouvrier 3' },
   
  // ]
  workers :any
  city: any;
  city_codePostal: any;
  myUUID = uuid.v4();

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private subContractorService :SubContractorService,
    private contactService :ContactService,
    public iziToast: Ng2IzitoastService,
    private workerService :WorkerService,
    private modalService: NgbModal,

    ) { }


  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    this.registerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      tva: ['', ],
      address : ['', ],
      number : ['', ],
      postalCode : ['', ],
      city : ['', ],
      contact: ['',],
      contactMain :['',],
      email:['', Validators.email],
      phone :['', ],
      worker :['', ],
      tauxTime : ['',],
      document :['',],
      key :['',]
      

      // acceptTerms: [false, Validators.requiredTrue]
  },);
  this.getContactsNotAffected()
  this.getWorkersNotAffected()
}

getContactsNotAffected() {
  this.contactService.getContacts().subscribe((e) => {
    this.contacts = e;
  });

}

getWorkersNotAffected() {
  this.workerService.getWorkerNotAffected().subscribe((e) => {
    this.workers = e;
  });

}


// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
    this.submitted = true;
    let filesList =[]
    //stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    if(this.filesToSaved.length != 0)
    {
      for(let file of this.filesToSaved)
      {
        //const newFileName = 'hello' +'.'+this.getFileExtension(file.name);; // Change this to the new file name
        const originalFileName = file.name;
        let fileExtension = this.getFileExtension(originalFileName);
        fileExtension ='.'+fileExtension
         const timestamp = Date.now().toString().slice(-2); // Get the last two digits of the timestamp
         let index =this.filesToSaved.indexOf(file) 
         index =index+1
         var parts = originalFileName.split('.');
        const newFileName = this.registerForm.value.companyName+'_'+parts[0]+'_'+index+'.'+parts[1]
        const newFile: File = new File([file], encodeURIComponent(newFileName), {type: file.type});
        const formData: FormData = new FormData();
        formData.append('file', newFile);
        this.subContractorService.uploadFiles(formData,this.myUUID).subscribe(e =>{
        })
        
        filesList.push(newFileName)
      }
      this.registerForm.value.document = filesList
      
    }
    if(this.registerForm.value.phone){
      this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
    }
    if(this.registerForm.value.postalCode){
    this.registerForm.value.postalCode=this.registerForm.value.postalCode["postal_code"]
    }
    const exists = this.registerForm.value.contact.includes(this.registerForm.value.contactMain); 
  
    if(this.registerForm.value.contactMain && !exists ){
      let msg = "Le contact principal choisi n'existe pas dans la liste des contacts de ce client."
      this.iziToast.show({
        message: msg,
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      this.registerForm.patchValue({
        contactMain: '' // Update contactMain to an empty string
      });
      return;
    }
    
    this.registerForm.value.key = this.myUUID
    this.subContractorService.create_subContractor(this.registerForm.value).subscribe(
      (data) => {
        this.iziToast.show({
          message:'Ajout avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
        this.router.navigate(['/admin/prestataires' ])
      })



    

}

public dropped(files: NgxFileDropEntry[]) {
 
  this.files = files;
  const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
  const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];

  if (validExtensions.includes(fileExtension)) {
  this.listFiles.push(this.files[0].relativePath)
  }
  else
  {
      const targetRelativePath = this.files[0].relativePath;
      const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

      if (targetIndex !== -1) {
        files.splice(targetIndex, 1);
      } 
    this.iziToast.show({
      message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
    return;
  }
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        if(file.size < 5242880){
        this.filesToSaved.push(file)
        }
        else
        {
          const targetRelativePath = this.files[0].relativePath;
          const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
          if (targetIndex !== -1) {
            this.listFiles.splice(targetIndex, 1);
          } 
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay:true,
            overlayClose:true,
            
          });
          return;
        }
        if(this.filesToSaved.length > 10){
          this.listFiles.splice(this.filesToSaved.length-1 , 1);
          this.filesToSaved.splice(this.filesToSaved.length-1 , 1);
          this.iziToast.show({
            message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return;
        }
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}


getCity()
{
  if(this.registerForm.value.postalCode != null)
  {
  this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
// => {name: "Albania", code: "AL"}
      this.registerForm.value.city = this.city
      this.city_codePostal = this.city["place_name"];
      this.registerForm.value.postalCode = this.city["postal_code"]
  }
  else{
    this.city_codePostal = ""
  }

}

deleteFileFromListFiles(file){
const indexToRemove = this.listFiles.findIndex(obj => obj[0].relativePath === file);
if (indexToRemove !== -1) {
  this.listFiles.splice(indexToRemove, 1);
  this.filesToSaved.splice(indexToRemove, 1);
}
 }


 getContactByThisClient(){
  this.listContactsByThisSubContractor =this.contacts.filter(item => this.registerForm.value.contact.includes(item._id));
}
 getFileExtension(fileName: string): string {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}
addContact(){
  let modalRef
  modalRef =this.modalService.open(PopupAddContactComponent);
  }
}
