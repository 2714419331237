import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { event } from 'jquery';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { ContactService } from 'src/app/_services/contact.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SiteService } from 'src/app/_services/site.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-list-sites',
  templateUrl: './list-sites.component.html',
  styleUrls: ['../../../_shared/list.css','./list-sites.component.css']
})
export class ListSitesComponent implements OnInit {

  clients = [
    // {  name: 'Client 1' },
    // {  name: 'Client 2' },
    // {  name: 'Client 3' },
    // {  name: 'Client 4' },
    
  ]
  listSiteManager = [
    // {  name: 'Solenn' },
    // {  name: 'Karen' },
    // {  name: 'Valérie' },
  ]
  listestablishment = [
    // {  name: 'Etablissement  1' },
    // {  name: 'Etablissement  2' },
    // {  name: 'Etablissement  3' },
   
  ]

  statuts =["STOP","ACTIF","ONE SHOT","OFFRE"]


  criteria: SortCriteria;
  decend = false
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  col5 = false
  col6 = false

  archived = false
  variable: string; // variable liée à l'archivage
  variable2: string; // variable liée à  not 'archivage
  sites: any;
  copyListSites :any
  copyListSitesArchiveds :any
  listFilred = []
  etatSite : any
  siteArchived_restore: any;
  etablissementToFiltred: any
  maangerToFiltred: any
  clientToFiltred :any
  statutToFiltred :any
  nameToToFiltred :any
  numberSiteToFiltred :any
  listArchiveds = []
  siteAdressToFiltred: any;
  listCities: any;
  citiesToFiltred: any;


  constructor( private router: Router ,private sharedService: SharedService,
    private siteService :SiteService, public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService) { 
   
    
  }

  ngOnInit(): void {

    this.variable = this.sharedService.getVariable();
    if(!this.variable){
      this.variable == "NotArchive"
    }
      if(this.variable == "archive"){
        this.archived = true
        this.displayListArchivedSites()
      }
      else{
        this.getSites()
      }
      localStorage.removeItem("lastPageContactVisited")
      localStorage.removeItem("lastPageClientVisited")
      localStorage.removeItem("lastPageBenefitVisited")
      localStorage.removeItem("lastPageDocumentVisited")
      localStorage.removeItem("lastPageEtablissementVisited")
      localStorage.removeItem("lastPageWorkerVisited")
      localStorage.removeItem("lastPageSubContractorVisited")
      localStorage.removeItem("lastPageSuppafacVisited")
      localStorage.removeItem("lastPageGlassVisited")


      if(localStorage.getItem("lastPageSiteVisited") && 
    localStorage.getItem("lastPageSiteVisited") != undefined && 
    localStorage.getItem("lastPageSiteVisited") != "")
   this.page = Number(localStorage.getItem("lastPageSiteVisited"))
  }

  getSites() {
    this.siteService.getSites().subscribe((e) => {
      this.sites = e;
      this.copyListSites = this.sites
      this.listestablishment =  Array.from(new Set(this.sites.filter(item => item.column1 !== "").map(item => item.column1)))
      this.listSiteManager =  Array.from(new Set(this.sites.map(item => item.column5)));
      this.clients =   Array.from(new Set(this.sites.map(item => item.column4)));
      this.statuts =   Array.from(new Set(this.sites.map(item => item.column6)));
      this.listCities =  Array.from(new Set(this.sites.map(item => item.city)));
    });
  }
  create() {
    this.router.navigate(["admin/chantiers/ajout"]);
  }
  details(id) {
    //this.router.navigate(["admin/chantiers/details", id])
    const url = `admin/chantiers/details/${id}`;
    window.open(url, '_blank');
  }
  detailsArchive(id) {
    this.router.navigate(["admin/chantiers/details", id])
  }

  update(id) {
    this.router.navigate(["admin/chantiers/modification", id])
  }

  addBenefit(id){
    this.sharedService.setIdSiteSendTOBenefit(id);
    this.router.navigate(["admin/prestations/ajout"]);
  }
  

  
  onTableDataChange(event: any) {
    this.page = event;
    this.sites = this.sites
    localStorage.setItem("lastPageSiteVisited",this.page.toString())
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getSites()
  }

  sort(column,decend,col)
   {
    
  switch (column) {
    
    case "column1":
      if(this.col1 == false){
      this.col1 = true
      this.col2 = false
      this.col3 = false
      this.col4 = false
      this.col5 = false
      this.col6 = false
      }
      else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column2":  
    if(this.col2 == false){
      this.col1 = false
      this.col2 = true
      this.col3 = false
      this.col4 = false
      this.col5 = false
      this.col6 = false
    }
    else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column3":
      if(this.col3 == false){  
      this.col1 = false
      this.col2 = false
      this.col3 = true
      this.col4 = false
      this.col5 = false
      this.col6 = false  
      }
      else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "adress":   
    if(this.col4 == false){   
      this.col1 = false
      this.col2 = false
      this.col3 = false
      this.col4 = true
      this.col5 = false
      this.col6 = false  
    }
    else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column5":  
    if(this.col5 == false){   
      this.col1 = false
      this.col2 = false
      this.col3 = false
      this.col4 = false
      this.col5 = true
      this.col6 = false
    }
    else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column6":
      if(this.col6 == false){   
      this.col1 = false
      this.col2 = false
      this.col3 = false
      this.col4 = false
      this.col5 = false
      this.col6 = true
      }
      else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    default :
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false   
        break   

  }

    if(column == "column6"){
    if(decend == false){
    this.criteria = {
      property: column ,
      descending: decend
    };
    this.decend = true
    }
    else{
      this.criteria = {
        property: column ,
        descending: decend
      };
      this.decend = false
    }
  }
  else
  {
   
      if(decend == false){
      this.criteria = {
        property: column ,
        descending: true
      };
      this.decend = true
      }
      else{
        this.criteria = {
          property: column ,
          descending: false
        };
        this.decend = false
      }
  
   }
}

displayListArchivedSites()
{
  this.resetFilters()
  this.etablissementToFiltred = undefined
  this.clientToFiltred = undefined
  this.maangerToFiltred =undefined
  this.nameToToFiltred = undefined
  this.numberSiteToFiltred = undefined

  this.archived = true
  this.page = 1
  //this.listArchiveds = this.sites
  this.siteService.getSitesArchiveds().subscribe((e) => {
    // this.listArchiveds = e;
    // this.copyListSitesArchiveds =  this.listArchiveds
    this.sites = e;
    this.copyListSites = this.sites
    this.listestablishment =  Array.from(new Set(this.sites.filter(item => item.column1 !== "").map(item => item.column1)))
    this.listSiteManager =  Array.from(new Set(this.sites.map(item => item.column5)));
    this.clients =   Array.from(new Set(this.sites.map(item => item.column4)));
    this.statuts =   Array.from(new Set(this.sites.map(item => item.column6)));
    this.listCities =  Array.from(new Set(this.sites.map(item => item.city)));
  });
}

displayListSites()
{
  this.resetFilters()
  this.page = 1
  this.archived = false
  this.getSites()
  
}

getsite(site) {
  this.siteArchived_restore = site;
  this.etatSite = this.siteArchived_restore.archive
}
archiveSite(site: any) {
  site = this.siteArchived_restore;
  let index = this.sites.findIndex(sit => sit._id === site._id);
  let lastDigit = index % 10;
   if (lastDigit === 0) {
    this.page--;
    localStorage.setItem("lastPageSiteVisited",this.page.toString())
  }
  if(site.archive == false)
  {
  this.siteService
    .archive_restore(site._id)
    .subscribe((e) => {
      this.iziToast.show({
        message: "Archivage avec succès",
        messageColor: "#343A40",
        progressBarColor: "#212529",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#CED4DA",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      this.displayListArchivedSites()
      this.page = 1
    });
  }
  else
  {
    
    this.siteService
      .archive_restore(site._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Désarchivage avec succès",
          messageColor: "#386641",
          progressBarColor: "#6a994e",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#dde5b6",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
       
       this.displayListSites()
      
      });
    }
}

// les filtres 

filterByEtablissement(event){
  this.etablissementToFiltred = event
}
filterByClient(event){
  this.clientToFiltred = event
}

filterByManger(event){
  this.maangerToFiltred = event
}

filterByStatut(event){
  this.statutToFiltred = event
}

filter() {
  this.sites = this.copyListSites;
  let filteredSites = [...this.copyListSites];
  const citiesilterLower = this.citiesToFiltred ? this.citiesToFiltred.toLowerCase() : '';
  const siteAdressFilterLower = this.siteAdressToFiltred ? this.siteAdressToFiltred.toLowerCase() : '';
  const statutFilterLower = this.statutToFiltred ? this.statutToFiltred.toLowerCase() : '';
  const nameFilterLower = this.nameToToFiltred ? this.nameToToFiltred.toLowerCase() : '';
  const numberSiteFilter = this.numberSiteToFiltred ? this.numberSiteToFiltred : '';
  filteredSites = this.sites.filter(item => {
    const statutFilter = statutFilterLower ? item.column6.toLowerCase() === statutFilterLower : true;
    const nameFilter = nameFilterLower ? item.column3.toLowerCase().includes(nameFilterLower) : true;
    const numberSiteFilterCondition = nameFilter ? item.column2.toLowerCase().includes(numberSiteFilter) : true;
    const adressFilter = siteAdressFilterLower ? item.adress.toLowerCase().includes(siteAdressFilterLower) : true;
    const citiesFilter = citiesilterLower ? item.city.toLowerCase() === citiesilterLower : true;


    return   statutFilter && nameFilter && numberSiteFilterCondition && adressFilter && citiesFilter;
  });
   this.sites = filteredSites;

   // Reset pagination
   this.page = 1;
}

resetFilters() {
  this.siteAdressToFiltred = ""; 
  this.statutToFiltred = undefined; 
  this.nameToToFiltred = "";
  this.numberSiteToFiltred = "";
  this.citiesToFiltred = undefined;

 this.filter();
}
 exportToExcel(sites: any[]): void {
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
  const data = sites.map(site => {
    // Remplacer les occurrences de "/n" par un espace dans l'adresse
    const formattedAddress = site.adress.replace(/\/n/g, ' ');
    return {
      'N° CHANTIER': site.column2, 
      'NOM ': site.column3,
      'ADRESSE': formattedAddress,
      'STATUT': site.column6,
    };
  });
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const columnWidths = [
    { wpx: 120 },
    { wpx: 200 }, 
    { wpx: 220 }, 
    { wpx: 150 }, 
  ];
  ws['!cols'] = columnWidths;
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Chantiers');
  const filename = `${currentDate}_chantiers.xls`;
  XLSX.writeFile(wb, filename );
}
getAddressParts(address) {
  const parts = address.split('/n');
  const street = parts.slice(0, parts.length - 3).join('/n');
  const number = parts[parts.length - 3];
  const postalCode = parts[parts.length - 2];
  const city = parts[parts.length - 1];
  return { street, number, postalCode, city };
}
}
