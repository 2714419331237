<!-- main app container -->
<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item color-blue">
        <a class="color-blue" routerLink="../../">Vitres</a>
      </li>
      <li class="breadcrumb-item active color-blue" style="cursor: default">
        Modifier une prestation vitres
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%">
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1">
            Modifier une prestation vitres
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
      </div>
    </div>
  </div>
</header>
<br />
<div class="card m-3-cust">
  <div *ngIf="benefit" class="card-body ml-5">
    <form
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit()"
      class="margin-right-50"
    >
      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Chantier<b style="color: red"> * </b></label>
          <ng-select
            [items]="sites"
            bindLabel="name"
            formControlName="site"
            [(ngModel)]="benefit.site"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.site.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.site.errors" class="invalid-feedback">
            <div *ngIf="f.site.errors.required">Champ obligatoire.</div>
          </div>
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Type de prestation<b style="color: red"> * </b></label>
          <i
            style="cursor: pointer"
            class="bi bi-plus-circle-fill"
            (click)="addType()"
          ></i>
          <ng-select
            (click)="getTypesBenefitsGlass()"
            [items]="typesBenefitGlass"
            bindLabel="type"
            [(ngModel)]="benefit.type"
            formControlName="type"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
          >
          </ng-select>
          <div
            *ngIf="submitted && registerForm.get('type').errors"
            class="invalid-feedback"
          >
            <div *ngIf="registerForm.get('type').errors.required">
              Champ obligatoire.
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label>Description</label>
          <textarea
            type="text"
            formControlName="description"
            class="form-control input-custom"
            [(ngModel)]="benefit.description"
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
          >
          </textarea>
          <div
            *ngIf="submitted && f.description.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.description.errors.maxlength">
              Ce champ peut comporter 200 caractères maximum.
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Prestataire<b style="color: red"> * </b></label>
          <ng-select
            [items]="listSubContractors"
            bindLabel="companyName"
            [(ngModel)]="benefit.subContractor"
            formControlName="subContractor"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.subContractor.errors }"
          >
          </ng-select>
          <div
            *ngIf="submitted && f.subContractor.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.subContractor.errors.required">
              Champ obligatoire.
            </div>
          </div>
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label
            >Contrat sous-traitant signé<b style="color: red"> * </b></label
          >
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="contratSST"
                [value]="true"
                [(ngModel)]="benefit.contratSST"
              />
              Oui
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="contratSST"
                [value]="false"
                [(ngModel)]="benefit.contratSST"
              />
              Non
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Contact<b style="color: red"> * </b></label>
          <ng-select
            [items]="contacts"
            bindLabel="firstName_lastName"
            formControlName="contact"
            [(ngModel)]="benefit.contact"
            class="form-control"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
            <div *ngIf="f.contact.errors.required">Champ obligatoire.</div>
          </div>
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Date d’intervention <b style="color: red"> * </b></label>
          <input
            type="date"
            min="{{ d1 }}"
            formControlName="startDate"
            class="form-control input-custom"
            [(ngModel)]="benefit.startDate"
            [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }"
          />
          <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
            <div *ngIf="f.startDate.errors.required">Champ obligatoire.</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Fréquence<b style="color: red"> *</b></label>
          <ng-select
            [items]="frequences"
            formControlName="frequence"
            (change)="getFreguence($event)"
            class="form-control input-custom"
            [(ngModel)]="benefit.frequence"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.frequence.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.frequence.errors" class="invalid-feedback">
            <div *ngIf="f.frequence.errors.required">Champ obligatoire.</div>
          </div>
        </div>

        <div
          class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          *ngIf="frequenceSelected == 'Hebdomadaire'"
          style="padding-top: 2%"
        >
          <div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <!--le jour lundi-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('01') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox1"
                      value="monday"
                      checked
                      (change)="chooseDate('Monday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('01') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox1"
                      value="monday"
                      (change)="chooseDate('Monday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox1"
                      value="monday"
                      (change)="chooseDate('Monday')"
                    />
                    <label class="form-check-label" for="myCheckbox1">
                      <span class="checkbox-label">Lundi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <!--le jour mardi-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('02') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox2"
                      checked
                      (change)="chooseDate('Tuesday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('02') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox2"
                      (change)="chooseDate('Tuesday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox2"
                      (change)="chooseDate('Tuesday')"
                    />
                    <label class="form-check-label" for="myCheckbox2">
                      <span class="checkbox-label">Mardi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <!--le jour mercredi-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('03') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox3"
                      checked
                      (change)="chooseDate('Wednesday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('03') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox3"
                      (change)="chooseDate('Wednesday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox3"
                      (change)="chooseDate('Wednesday')"
                    />
                    <label class="form-check-label" for="myCheckbox3">
                      <span class="checkbox-label">Mercredi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <!--le jour jeudi-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('04') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox4"
                      checked
                      (change)="chooseDate('Thursday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('04') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox4"
                      (change)="chooseDate('Thursday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox4"
                      (change)="chooseDate('Thursday')"
                    />
                    <label class="form-check-label" for="myCheckbox4">
                      <span class="checkbox-label">Jeudi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <!--le jour vendredi-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('05') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox5"
                      checked
                      (change)="chooseDate('Friday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('05') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox5"
                      (change)="chooseDate('Friday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox5"
                      (change)="chooseDate('Friday')"
                    />
                    <label class="form-check-label" for="myCheckbox5">
                      <span class="checkbox-label">Vendredi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <!--le jour samedi-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('06') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox6"
                      checked
                      (change)="chooseDate('saturday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('06') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox6"
                      (change)="chooseDate('saturday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox6"
                      (change)="chooseDate('saturday')"
                    />
                    <label class="form-check-label" for="myCheckbox6">
                      <span class="checkbox-label">Samedi</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                  <!--le jour dimanche-->
                  <div class="form-check form-check-inline">
                    <input
                      *ngIf="
                        benefit.listDates.includes('07') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox7"
                      checked
                      (change)="chooseDate('sunday')"
                    />
                    <input
                      *ngIf="
                        !benefit.listDates.includes('07') &&
                        initialfrenquency == 'Hebdomadaire'
                      "
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox7"
                      (change)="chooseDate('sunday')"
                    />
                    <input
                      *ngIf="initialfrenquency != 'Hebdomadaire'"
                      class="form-check-input"
                      type="checkbox"
                      id="myCheckbox7"
                      (change)="chooseDate('sunday')"
                    />
                    <label class="form-check-label" for="myCheckbox7">
                      <span class="checkbox-label">Dimanche</span>
                      <span class="checkbox-box"></span>
                    </label>
                  </div>
                </div>
                <div
                  style="font-size: 0.875em; color: #f36"
                  *ngIf="validWeeklyBenefitForm == false"
                >
                  Champ obligatoire.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"  *ngIf="frequenceSelected == 'Hebdomadaire'"
      style="padding-top: 2%">
        <div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox1"
                    value="monday"
                    (change)="chooseDate('Monday')"
                  />
                  <label class="form-check-label" for="myCheckbox1">
                    <span class="checkbox-label" >Lundi</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox2"
                    (change)="chooseDate('Tuesday')"
                  />
                  <label class="form-check-label" for="myCheckbox2">
                    <span class="checkbox-label">Mardi</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox3"
                    (change)="chooseDate('Wednesday')"
                  />
                  <label class="form-check-label" for="myCheckbox3">
                    <span class="checkbox-label" >Mercredi</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox4"
                    (change)="chooseDate('Thursday')"
                  />
                  <label class="form-check-label" for="myCheckbox4">
                    <span class="checkbox-label">Jeudi</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox5"
                    (change)="chooseDate('Friday')"
                  />
                  <label class="form-check-label" for="myCheckbox5">
                    <span class="checkbox-label">Vendredi</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox6"
                    (change)="chooseDate('saturday')"
                  />
                  <label class="form-check-label" for="myCheckbox6">
                    <span class="checkbox-label">Samedi</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="myCheckbox7"
                    (change)="chooseDate('sunday')"
                  />
                  <label class="form-check-label" for="myCheckbox7">
                    <span class="checkbox-label">Dimanche</span>
                    <span class="checkbox-box"></span>
                  </label>
                </div>
              </div>
              <div style="font-size: 0.875em;color: #f36;" *ngIf="validWeeklyBenefitForm == false">Champ obligatoire.</div>
            </div>
          </div>
        </div>


      </div> -->

        <div
          *ngIf="frequenceSelected == 'Annuelle'"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div>
            <label>Mois <b style="color: red"> *</b></label>
            <ng-select
              [items]="listMonthsannual"
              class="form-control input-custom"
              [closeOnSelect]="true"
              formControlName="listOfMonths"
              (change)="getSelectedMonth($event)"
              [(ngModel)]="monthChoised_frenquency_annuelle"
              *ngIf="registerForm.contains('listOfMonths')"
              [ngClass]="{ 'is-invalid': submitted && f.listOfMonths.errors }"
            >
            </ng-select>
            <div
              *ngIf="submitted && f.listOfMonths.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.listOfMonths.errors.required">
                Champ obligatoire.
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="frequenceSelected == 'Bi-annuelle'"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div>
            <label>Mois <b style="color: red"> *</b></label>
            <ng-select
              (click)="gemothsBiAnnual()"
              [items]="listMonthsannual_Bi_annual"
              bindLabel="name"
              class="form-control input-custom"
              [closeOnSelect]="true"
              [(ngModel)]="this.monthsChoised_frenquency_BiAnnuelle"
              formControlName="listOfMonthsBiAnnual"
              multiple="true"
              (change)="getSelectedMonthBiAnnual($event)"
              *ngIf="registerForm.contains('listOfMonthsBiAnnual')"
              [ngClass]="{
                'is-invalid': submitted && f.listOfMonthsBiAnnual.errors
              }"
            >
            </ng-select>
            <div
              *ngIf="submitted && f.listOfMonthsBiAnnual.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.listOfMonthsBiAnnual.errors.required">
                Champ obligatoire.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div
          *ngIf="benefit.typesMaterial[0] != ''"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <label>Dispositif</label>
          <ng-select
            [items]="typesMaterial"
            formControlName="typesMaterial"
            bindLabel="name"
            [(ngModel)]="benefit.typesMaterial"
            (click)="initTypeMaterail()"
            class="form-control"
            (change)="recuperateListMaterials($event)"
            [closeOnSelect]="true"
            multiple="true"
          >
          </ng-select>
        </div>
        <div
          *ngIf="benefit.typesMaterial[0] == ''"
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <label>Dispositif</label>
          <ng-select
            [items]="typesMaterial"
            formControlName="typesMaterial"
            bindLabel="name"
            (click)="initTypeMaterail()"
            class="form-control"
            (change)="recuperateListMaterials($event)"
            [closeOnSelect]="true"
            multiple="true"
          >
          </ng-select>
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Numéro Osiris </label>
          <input
            type="text"
            formControlName="numerosOsiris"
            [(ngModel)]="benefit.numerosOsiris"
            class="form-control"
          />
        </div>

        <div
          *ngIf="this.benefit.typesMaterial.includes('Camions')"
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div class="row">
            <div>
              <label>Plaque <b style="color: red"> * </b></label>
              <input
                type="text"
                formControlName="plate"
                [(ngModel)]="benefit.plaque"
                class="form-control input-custom"
                *ngIf="registerForm.contains('plate')"
                [ngClass]="{ 'is-invalid': submitted && f.plate.errors }"
              />
              <div *ngIf="submitted && f.plate.errors" class="invalid-feedback">
                <div *ngIf="f.plate.errors.required">Champ obligatoire.</div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="shouldShowPlaque()"
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <div class="row">
            <div>
              <label>Plaque <b style="color: red"> * </b></label>
              <input
                type="text"
                formControlName="plaque"
                [(ngModel)]="benefit.plaque"
                class="form-control input-custom"
                [ngClass]="{ 'is-invalid': submitted && benefit.plaque == '' }"
              />
              <div
                *ngIf="submitted && benefit.plaque == ''"
                class="invalid-feedback"
              >
                <div *ngIf="submitted && benefit.plaque == ''">
                  Champ obligatoire.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="shouldShowPlaque()" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div >
            <label>Plaque <b style="color: red;"> * </b></label>
            <input type="text"  formControlName="plate"  *ngIf="registerForm.contains('plate')"
             class="form-control input-custom" 
             [ngClass]="{ 'is-invalid': submitted && f.plate.errors }" />
             <div *ngIf="submitted && f.plate.errors" class="invalid-feedback">
              <div *ngIf="f.plate.errors.required">Champ obligatoire.</div>
          </div>
          </div>
        </div>
      </div>  -->

        <div
          *ngIf="
            this.benefit.typesMaterial.includes('Nacelles') ||
            this.benefit.typesMaterial.includes('Potences') ||
            shouldShowAttestationControl()
          "
          class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
        >
          <label>Attestation de contrôle</label>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="controlCertificate"
                [value]="true"
                [(ngModel)]="benefit.controlCertificate"
              />
              Oui
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <input
                type="radio"
                formControlName="controlCertificate"
                [value]="false"
                [(ngModel)]="benefit.controlCertificate"
              />
              Non
            </div>
          </div>
        </div>
      </div>

      <div style="padding-top: 3%" class="text-center">
        <button
          routerLink="/admin/chantiers/vitres"
          class="btn btn-secondary mr-4 button_list mb-2"
          type="reset"
        >
          Annuler
        </button>
        <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
<br />

<!-- credits -->
