import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  private _sitetURL=`${environment.baseUrl}/sites`
  constructor(private http: HttpClient) { }

  uploadFiles(files,key):Observable <any> {
    return this.http.post(`${this._sitetURL}/upload/file/${key}`,files);
        }

     getSites() :Observable <any> {
      return this.http.get(`${this._sitetURL}`);
      }

      //get liste des chantiers non archivés 
      getSitesArchiveds() :Observable <any> {
        return this.http.get(`${this._sitetURL}/list_sites/archiveds`);
        }
 
      createSite(site: any):Observable<Object> {
        return this.http.post(`${this._sitetURL}`,site);
      }
       
     getSiteById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._sitetURL}/${id}`);
      } 
      
      EditSite(id:string,site:any):Observable<Object> {
        return this.http.put(`${this._sitetURL}/${id}`,site);
      }
     
    archive_restore(id :any){
      return this.http.get(`${this._sitetURL}/archive-restoreSite/${id}`);
    }

        // get list documents in subcontrator 
        listNamesFiles(id : string):Observable<any>
        {
          return this.http.get<any>(`${this._sitetURL}/files/listNames/${id}`);
        } 

         // get list contacts by site  
         listContactsBySite(id : string):Observable<any>
         {
           return this.http.get<any>(`${this._sitetURL}/list-contacts-by-site/${id}`);
         } 


         // 11-09-2023
        // get list sites by client 
        listSitesByClient(id : string):Observable<any>
        {
          return this.http.get<any>(`${this._sitetURL}/listSites-by-client/${id}`);
        } 

        listSitesByEtablissement(id : string):Observable<any>
        {
          return this.http.get<any>(`${this._sitetURL}/listSites-by-etablissement/${id}`);
        } 

        listSitesByContact(id : string):Observable<any>
        {
          return this.http.get<any>(`${this._sitetURL}/listSites-by-contact/${id}`);
        } 


        getNumberLastSiteAdded(){
          return this.http.get(`${this._sitetURL}/number/lastSite`);
        }


    getSites_not_Stopedt():Observable<any>
    {
      return this.http.get<any>(`${this._sitetURL}/not/Stoped`);
    } 

    //  // get client d'ont have etablissement

    //  getClientsWithNotEtablissement():Observable<any>
    //  {
    //    return this.http.get<any>(`${this._etablissementURL}/allClients/ByAllEtablissement`);
    //  } 

    }
