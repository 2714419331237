import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Ng2IzitoastService } from 'ng2-izitoast';
import codes from 'src/app/BE_Postal_codes.json';
import contries from 'src/app/List_countries.json';

import { ClientService } from 'src/app/_services/client.service';
import { ContactService } from 'src/app/_services/contact.service';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { PopupAddContactComponent } from '../../popupAdd/popup-add-contact/popup-add-contact.component';
import { PopupAddEtablissementComponent } from '../../popupAdd/popup-add-etablissement/popup-add-etablissement.component';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css','../../../../_shared/add_update.css']
})
export class AddClientComponent implements OnInit {
  postalCode: string ="1099";
  city :any 
  registerForm: FormGroup;
  submitted = false;
  city_codePostal : any = ""
  autoliquidationVerif = false
  contacts :any
  listContactsByThisClient :any
  etablissements :any
  codes_postales :any = codes 
  list_contries = contries
chooseContactMainFromlistContactsByThisClient :any

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private clientService :ClientService,
    private contactService :ContactService,
    private etablissementService :EtablissementService,
    public iziToast: Ng2IzitoastService,
    private config: NgSelectConfig,
    private modalService: NgbModal,
    ) { 
      this.config.notFoundText = "Aucun élement trouvé"
    }


  ngOnInit() {
    this.codes_postales.forEach(item => {
      item.codePostal_placeName = item.postal_code + ' - ' + item.place_name;
    });
    let city = codes.find(el => el.postal_code === "2060");
    this.registerForm = this.formBuilder.group({
        companyName: ['', Validators.required],
        tva: ['', ],
        autoliquidation: ['', ],
        country: ['', ],
        street : ['', ],
        number : ['', ],
        postalCode : ['', ],
        city : ['', ],
        contact: ['', ],
        constructionSite: [''],
        email:['', Validators.email],
        contactMain: ['',], // principale
        etablissement : ['', ],
    },);
    this.getContactsNotAffected()
    this.getEtablissement()
}


getContactsNotAffected() {

  this.contactService.getContacts().subscribe((e) => {
    this.contacts = e;
  });
}

getEtablissement() {
  this.etablissementService.getEtablissements().subscribe((e) => {
    this.etablissements = e;
  });
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }

  // display form values on success
  //this.registerForm.value.autoliquidation = this.autoliquidationVerif
  if(this.registerForm.value.country === "Belgique" && this.registerForm.value.postalCode != "" ){
    this.registerForm.value.postalCode = this.registerForm.value.postalCode["postal_code"]
  }
  if(this.registerForm.value.autoliquidation == ""){
    this.registerForm.value.autoliquidation = false
  }
    this.clientService.createClient(this.registerForm.value).subscribe(
      (data) => {
       
        
        this.iziToast.show({
          message:'Ajout avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
           this.router.navigate(['/admin/clients' ])
          })
}

onReset() {
    // this.submitted = false;
    // this.registerForm.reset();

}


getCity()
{
  if(this.registerForm.value.postalCode != null)
  {
  this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
      this.registerForm.value.city = this.city
      this.city_codePostal = this.city["place_name"];
  }
  else{
    this.city_codePostal = ""
  }

}

getContactByThisClient(){
  this.listContactsByThisClient =this.contacts.filter(item => this.registerForm.value.contact.includes(item._id));
  if(this.listContactsByThisClient.length === 1){
    this.chooseContactMainFromlistContactsByThisClient = this.listContactsByThisClient[0]
    this.registerForm.value.contactMain = this.chooseContactMainFromlistContactsByThisClient._id
  }
  else{
    this.chooseContactMainFromlistContactsByThisClient = null
    this.registerForm.value.contactMain = null
  }
}

setContactMain(contact){
  this.registerForm.value.contactMain = contact._id
}

addContact(){
  let modalRef
  modalRef =this.modalService.open(PopupAddContactComponent);
  modalRef.componentInstance.client = null;
  }

  addEtablissement(){
    let modalRef
    modalRef =this.modalService.open(PopupAddEtablissementComponent);
  }
}
