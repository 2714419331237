<!-- main app container -->
<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item color-blue">
        <a class="color-blue" routerLink="../">Établissements</a>
      </li>
      <li class="breadcrumb-item active color-blue" style="cursor: default">
        Créer un établissement
      </li>
    </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%">
      <!-- <div class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
              Backoffice</a>
          </li>
          <li class="breadcrumb-item"><a routerLink="../">Articles</a></li>
          <li class="breadcrumb-item active">Ajouter</li>
        </ul>
      </div> -->
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1">
            Créer un établissement
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>

        <!-- Actions -->
        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mt-4 text-sm-end">
          <div class="mx-n1">
            <a
              (click)="create()"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
            
              <span class="button_list" style="color: #fff">Créer un client</span>
            </a>
            <a
              
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
            
              <span class="button_list" style="color: #fff">Export XLS</span>
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</header>
<br />
<div class="card m-3-cust">
  <div class="card-body ml-5">
    <form
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit()"
      class="margin-right-50"
    >
      <div class="form-row">
        <div class="form-group col-12">
          <label>Nom de l'établissement <b style="color: red"> *</b></label>
          <input
            type="text"
            formControlName="name"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
          />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Champ obligatoire.</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12" formArrayName="adress">
          <label
            >Adresse <b style="color: red"> * </b>
            <i
              *ngIf="
                this.registerForm.value.adress[0].street &&
                this.registerForm.value.adress[0].number &&
                this.registerForm.value.adress[0].postalCode
              "
              style="cursor: pointer"
              class="bi bi-plus-circle-fill"
              (click)="addQuantity()"
            ></i>
          </label>
          <div
            *ngFor="let quantity of adress().controls; let i = index"
            class="row"
            [formGroupName]="i"
          >
            <div
              class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom"
            >
              <label *ngIf="i > 0">
                <i
                  style="cursor: pointer"
                  class="bi bi-trash"
                  (click)="removeQuantity(i)"
                ></i>
              </label>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
              <label>Rue</label>
              <input
                type="text"
                formControlName="street"
                class="form-control"
              />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Numéro</label>
              <input
                type="text"
                formControlName="number"
                class="form-control"
              />
            </div>

            <div
              class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom"
            >
              <label *ngIf="i > 1000">
                <i style="cursor: pointer" class="bi bi-trash"></i>
              </label>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
              <label>Code postal</label>
              <ng-select
                [items]="codes_postales"
                bindLabel="codePostal_placeName"
                formControlName="postalCode"
                class="form-control input-custom"
                [closeOnSelect]="true"
                (change)="getCity(i)"
              >
              </ng-select>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Ville</label>
              <input
                disabled
                type="text"
                formControlName="city"
                [value]="
                  this.registerForm.value.adress[i].postalCode.place_name
                "
                class="form-control input-custom"
              />
            </div>
            <br />
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Responsable<b style="color: red"> * </b></label>
          <ng-select
            [items]="admins"
            bindLabel="firstName"
            bindValue="_id"
            formControlName="manager"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.manager.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.manager.errors" class="invalid-feedback">
            <div *ngIf="f.manager.errors.required">Champ obligatoire.</div>
          </div>
        </div>

        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label
            >Client(s)
            <i
              style="cursor: pointer"
              class="bi bi-plus-circle-fill"
              (click)="addClient()"
            ></i>
          </label>
          <ng-select
            (click)="getClients()"
            [items]="clients"
            bindLabel="companyName"
            ([ngModel])="(clientf)"
            formControlName="client"
            class="form-control input-custom"
            [closeOnSelect]="true"
            multiple="true"
            (change)="setListNamesClients()"
          >
          </ng-select>
        </div>
      </div>

      <div class="form-row">
        <!-- <p>123 {{clients[0] |json }}</p> -->
        <div
          *ngFor="let client of this.listNamesClients"
          class="form-group col-12"
        >
          <div>
            <label
              >Chantier(s) - <b>{{ client.companyName }}</b></label
            >

            <ng-select
              [items]="client.site"
              bindLabel="name"
              bindValue="_id"
              class="form-control input-custom"
              [closeOnSelect]="true"
              multiple="true"
              (change)="setListSites($event, client._id)"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div style="padding-top: 3%" class="text-center">
        <button
          routerLink="/admin/etablissements"
          class="btn btn-secondary mr-4 button_list mb-2"
          type="reset"
        >
          Annuler
        </button>
        <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
<br />

<!-- credits -->
