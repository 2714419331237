<br /><br /><br /><br /><br /><br />
<div class="container" id="container">
  <div class="form-container sign-in-container">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <h3 style="color: #5c5a7e !important">
        Réinitialiser votre mot de passe
      </h3>
      <div class="social-container">
        <a href="/" class="social"
          ><i
            class="bi bi-circle-fill"
            style="color: #0e309f; opacity: 25% !important"
          ></i
        ></a>
        <a href="/" class="social"
          ><i
            class="bi bi-circle-fill"
            style="color: #0e309f; opacity: 50% !important"
          ></i
        ></a>
        <a href="/" class="social"
          ><i
            class="bi bi-circle-fill"
            style="color: #0e309f; opacity: 100% !important"
          ></i
        ></a>
      </div>

      <input
        placeholder="Email"
        type="email"
        formControlName="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
      />
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Veuillez renseigner ce champ.</div>
        <div *ngIf="f.email.errors.pattern">
          Merci de saisir une adresse email valide.
        </div>
      </div>

      <br />
      <button style="color: #fff">Envoyer</button>
      <br />
    </form>
  </div>
  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-right">
        <img
          class="img-fluid"
          src="assets/logo-full-cleaning.png"
          alt=""
          width="250px"
          height="200px"
        />
        <br />
        <p style="color: #fff">
          Envoyer un e-mail de réinitialisation du mot de passe
        </p>
      </div>
    </div>
  </div>
</div>
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"
/>
